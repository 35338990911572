// import URLBuilder from './url';
import * as session from './session';
import * as configUrls from './configUrls';

const redirect = () => {
  // const url = new URLBuilder();
  /* eslint-disable */
  const redirectUri =
    session.getRedirectUri() || configUrls.REACT_APP_DEFAULT_URL;

  try {
    // add this after discussion with the doc teams
    // const newURL = new URL(redirectUri);
    // const params = new URLSearchParams(newURL.search) || {};
    // const url = new URLBuilder(`${newURL.origin}${newURL.pathname}`, params)
    //   .appendParams({ token: session.getSession().token })
    //   .getUrl();
    // window.location = url;
    const token = session.getSession().token || session.getSession()?.user?.token
    window.location = redirectUri + '?token=' + token;
  } catch (e) {
    console.error(e);
  }
};

export { redirect };
