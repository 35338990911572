import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteParameter = ({ params, fallback, children }) => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const shouldFallback = params.find((key) => !urlSearchParams.get(key));

  if (shouldFallback && params.length > 0) {
    return fallback;
  }
  return <>{children}</>;
};

RouteParameter.propTypes = {
  params: PropTypes.arrayOf(PropTypes.string),
  fallback: PropTypes.element,
  children: PropTypes.element,
};

RouteParameter.defaultProps = {
  params: [],
  fallback: undefined,
  children: undefined,
};

export default RouteParameter;
