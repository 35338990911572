import React, { useEffect, useState } from 'react';
import * as apiService from '@doctoroncallcom/api';
import * as session from '../../utils/session';
import * as configUrls from '../../utils/configUrls';
import useApiCall from '../../hooks/useApiCall';
import Loader from '../../components/Loader/Loader';

function Logout() {
  const [params, setParams] = useState(null);
  const logoutApi = useApiCall(params, apiService.user.logout);
  useEffect(() => {
    if (session.getSession()?.token) {
      setParams({ token: session.getSession().token });
    } else {
      window.location = configUrls.REACT_APP_DEFAULT_URL;
    }
  }, []);

  useEffect(() => {
    if (logoutApi.success) {
      session.resetSession();
      session.resetRedirectUri();
      session.resetUserDetails();
      window.location = configUrls.REACT_APP_DEFAULT_URL;
    }
  }, [logoutApi.success]);

  useEffect(() => {
    /* eslint-disable no-console */
    logoutApi.error && console.error(logoutApi.error);
  }, [logoutApi.error]);
  return (
    <div className="h-screen md:bg-gray-200 xl:px-56 lg:px-26 md:px-14 flex justify-center items-center">
      {logoutApi.loading && <Loader height={50} width={50} />}
    </div>
  );
}

export default Logout;
