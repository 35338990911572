import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import { inputTheme } from '../Input/Input';
import InputLabel from '../InputLabel/InputLabel';
import Modal from '../Modal/Modal';

const classNames = require('classnames');

export default function RegAlertModal(props) {
  const { width, height, onCancel, title, onConfirm } = props;
  return (
    <>
      <Modal toggleModal={onCancel} height={height} width={width} title={title}>
        <div className="py-4 px-2">
          <InputLabel
            theme={classNames(
              inputTheme.error,
              'pb-5 px-5 text-sm text-center'
            )}
            label="This account is not registered with us. Do you want to register now?"
            width="auto"
            height="auto"
          />
          <div className="flex justify-between">
            <Button
              type="button"
              onClick={onCancel}
              label="Cancel"
              primary={false}
              width="100px"
              height="42px"
            />
            <Button
              type="button"
              onClick={onConfirm}
              label="OK"
              primary={true}
              width="100px"
              height="42px"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

RegAlertModal.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
};

RegAlertModal.defaultProps = {
  title: '',
  width: '328px',
  height: '196px',
};
