import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as apiService from '@doctoroncallcom/api';
import { useLocation } from 'react-router-dom';
import * as session from '../../utils/session';
import useApiCall from '../../hooks/useApiCall';
import Loader from '../../components/Loader/Loader';
import { redirect } from '../../utils/redirect';
import routes from '../../config/routes';
import URL from '../../utils/url';
import api from '../../api/index';

const Auth = ({ children }) => {
  const location = useLocation();
  const [params, setParams] = useState(null);
  const { loading, data, error, success } = useApiCall(
    params,
    apiService.user.me
  );

  useEffect(() => {
    const urlParams = new URL().getParams();
    if (!(window.location.pathname === '/logout')) {
      const user = session.getSession();
      const token = user?.token || user?.user?.token;
      if (user && token) {
        setParams({});
      }
    }
    if (urlParams.token) {
      api.setAuthorizationToken(urlParams.token);
      setParams({});
    }
  }, []);
  useEffect(() => {
    if (success) {
      const auth = { ...session.getSession(), user: data.data };
      session.storeSession(auth);
      if (location.pathname !== routes.completeProfile) redirect();
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      /* eslint-disable no-console */
      console.error(error);
    }
  }, [error]);

  if (loading) return <Loader height={50} width={50} hasOverlay={true} />;

  return children;
};

Auth.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Auth;
