import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';
import 'react-datepicker/dist/react-datepicker.css';
import { inputTheme } from '../Input/Input';

export default function CalendarInput(props) {
  const {
    width,
    height,
    autoFocus,
    calendarClassName,
    className,
    customInputRef,
    dateFormat,
    dateFormatCalendar,
    disabled,
    dropdownMode,
    focusSelectedMonth,
    id,
    name,
    onClickOutside,
    onFocus,
    onKeyDown,
    onSelect,
    placeholderText,
    readOnly,
    required,
    scrollableYearDropdown,
    showMonthDropdown,
    showYearDropdown,
    title,
    formik,
    label,
    customInput,
  } = props;
  const years = range(1950, getYear(new Date()) + 1, 1);
  const months = moment.months();

  const { errors, touched } = formik;
  const { value: calendarValue, onBlur } = formik.getFieldProps(name);

  const onValueChange = (event) => {
    formik.setFieldValue(name, event);
  };

  return (
    <div style={{ width, height }}>
      {label}
      <DatePicker
        wrapperClassName={className}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex m-2.5	justify-around">
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </button>
          </div>
        )}
        onChange={onValueChange}
        customInput={customInput}
        calendarClassName={calendarClassName}
        className={className}
        autoFocus={autoFocus}
        customInputRef={customInputRef}
        dateFormat={dateFormat}
        dateFormatCalendar={dateFormatCalendar}
        disabled={disabled}
        dropdownMode={dropdownMode}
        focusSelectedMonth={focusSelectedMonth}
        id={id}
        name={name}
        onBlur={onBlur}
        onClickOutside={onClickOutside}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onSelect={onSelect}
        placeholderText={placeholderText}
        readOnly={readOnly}
        required={required}
        scrollableYearDropdown={scrollableYearDropdown}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        title={title}
        value={
          typeof calendarValue === 'object' &&
          moment(calendarValue).format('D MMM YYYY')
        }
        selected={calendarValue}
      />
      {errors[name] && touched[name] && (
        <div className={inputTheme.error}>{errors[name]}</div>
      )}
    </div>
  );
}

CalendarInput.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  autoFocus: PropTypes.bool,
  calendarClassName: PropTypes.string,
  className: PropTypes.string,
  customInput: PropTypes.element,
  customInputRef: PropTypes.string,
  dateFormat: PropTypes.string,
  dateFormatCalendar: PropTypes.string,
  disabled: PropTypes.bool,
  dropdownMode: PropTypes.string,
  focusSelectedMonth: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onClickOutside: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onSelect: PropTypes.func,
  placeholderText: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  scrollableYearDropdown: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
CalendarInput.defaultProps = {
  autoFocus: false,
  calendarClassName: 'defaultCalendar',
  className: '',
  customInput: undefined,
  customInputRef: '',
  dateFormat: '',
  dateFormatCalendar: '',
  disabled: false,
  dropdownMode: undefined,
  focusSelectedMonth: false,
  id: 'calendar',
  name: 'calendar',
  onClickOutside: undefined,
  onFocus: undefined,
  onKeyDown: undefined,
  onSelect: undefined,
  placeholderText: 'DD-MM-Y',
  readOnly: false,
  required: false,
  scrollableYearDropdown: undefined,
  showMonthDropdown: undefined,
  showYearDropdown: undefined,
  title: '',
  label: undefined,
};
