import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as apiService from '@doctoroncallcom/api';
import * as Yup from 'yup';
import { parsePhoneNumber } from 'react-phone-number-input';
import googleRecaptchaFunc from '../../utils/recaptcha';
import useApiCall from '../../hooks/useApiCall';
import Loader from '../../components/Loader/Loader';
import Button, { buttonTheme } from '../../components/Button/Button';
import Input, { inputTheme } from '../../components/Input/Input';
import InputLabel, {
  inputLabelTheme,
} from '../../components/InputLabel/InputLabel';
import Layout from '../../components/Layout/Layout';
import ForgetPasswordHeader from '../../components/Layout/ForgetPasswordHeader';
import BackButton from '../../components/Layout/BackButton';
import routes from '../../config/routes';
import URL from '../../utils/url';
import { validateEmail, validateMobile } from '../../utils/validation';
import ErrorResponse from '../../components/ErrorResponse/ErrorResponse';
import ErrorDisplayInline from '../../components/ErrorDisplayInline/ErrorDIsplayInline';
import GoBackAndInfo from '../../components/BackAndInfo/BackAndInfo';

function ForgetPassword() {
  const navigate = useNavigate();
  const [params, setParams] = useState(null);
  const [cc, setCc] = useState('');
  const [recaptchaError, setRecaptchaError] = useState('');
  const [recaptchaLoading, setRecaptchaLoading] = useState(false);
  const countriesCodeArr = ['+60', '+61', '+62', '+65'];

  const { loading, error, success, data } = useApiCall(
    params,
    apiService.otp.code
  );

  const validate = (values) => {
    const errors = {};
    let emailError = false;
    let phoneError = false;
    // Email Test
    if (!validateEmail(values.email)) {
      emailError = true;
    }
    // Phone Test
    /* eslint-disable */
    if (emailError && values.email?.length > 3) {
      if (values.email.includes('+6')) {
        const validation = validateMobile(values.email);
        if (validation) {
          setCc('+' + parsePhoneNumber(values.email).countryCallingCode);
        } else { phoneError = true; }
      } else {
        let matched = false;
        for (let i=0; i<countriesCodeArr.length;i++) {
          if (validateMobile(countriesCodeArr[i] + values.email)) {
            setCc(countriesCodeArr[i]);
            matched = true;
            return;
          }
        }
        if (matched === false) { phoneError = true; }
      }
    } else {
      setCc('');
    }

    if (emailError && phoneError) {
      errors.email = 'Please provide a valid Mobile number or Email address.';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      generalError: '',
    },

    validationSchema: Yup.object().shape({
      email: Yup.string().required('Required*'),
    }),
    validate,
    onSubmit: (values) => {
      setRecaptchaLoading(true);
      googleRecaptchaFunc()
        .then((token) => {
          setRecaptchaLoading(false);
          if (validateEmail(values.email)) {
            setParams({
              email: values.email.toLowerCase(),
              type: 'EMAIL',
              recaptchaToken: token
            });
          } else {
            setParams({
              phoneNumber: values.email.includes('+6') ? parsePhoneNumber(values.email).nationalNumber : parsePhoneNumber(cc + values.email).nationalNumber,
              type: 'SMS',
              countryCode: cc,
              recaptchaToken: token
            });
          }
        })
        .catch((errToken) => {
          setRecaptchaLoading(false);
          setRecaptchaError(errToken);
        });
    },
  });

  useEffect(() => {
    if (success) {
      const url = new URL(routes.forgotPasswordVerify)
        .appendParams({
          info: validateEmail(formik.values.email) ? formik.values.email.replace(/\s/g, '') : ((formik.values.email.includes('+6') ? parsePhoneNumber(formik.values.email).nationalNumber : parsePhoneNumber(cc + formik.values.email).nationalNumber)),
          type: validateEmail(formik.values.email) ? 'EMAIL' : 'SMS',
          sessionId: data.data.sessionId,
          cc,
          errorCode: null,
        })
        .getUrl();
      navigate(url);
    }
  }, [success]);

  useEffect(() => {
    /* eslint-disable no-console */
    if (error || recaptchaError) {
      const errorResponse = ErrorResponse(error || recaptchaError);
      formik.setErrors({
        generalError: errorResponse,
      });
    }
  }, [error, recaptchaError]);

  return (
    <Layout>
      <div className="w-full items-center h-full flex-col flex">
        <GoBackAndInfo showBack={true} />
        <main className="flex flex-col px-5 pt-10 lg:w-3/5 md:w-full sm:w-3/5 w-full">
          <ForgetPasswordHeader text="Forgot Password" />
          <p className="sm:hidden block py-4 font-light text-sm text-theme-gray leading-snug">
            We will send a one time password (OTP) to your Mobile Number / Email
            address
          </p>
          <form onSubmit={formik.handleSubmit} className="w-full pt-3">
            <div className="pb-2 w-full">
              <Input
                theme={inputTheme.plain}
                label={
                  <InputLabel
                    label="Enter Mobile Number or Email"
                    theme={inputLabelTheme.default}
                    height="30px"
                  />
                }
                type="text"
                disabled={false}
                readOnly={false}
                formik={formik}
                name="email"
                id="email"
                height="41px"
                width="100%"
                placeholder="+60123456789 / johndoe@gmail.com"
              />
            </div>
            <div className="py-3 w-full ">
              <Button
                type="submit"
                label="Next"
                primary={true}
                theme={buttonTheme.primary}
                isLoading={loading || recaptchaLoading}
                loader={<Loader height={15} width={15} color="white" />}
                disabled={loading || recaptchaLoading}
                width="100%"
                height="41px"
              />
            </div>
            <br />
            {formik.errors?.generalError ? (
              <ErrorDisplayInline error={formik.errors.generalError} />
            ) : null}
          </form>
        </main>
        <div className="flex-1"> </div>
        <div className="flex-1"> </div>
      </div>
    </Layout>
  );
}

export default ForgetPassword;
