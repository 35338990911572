import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { user } from '@doctoroncallcom/api';
import useApiCall from '../../../hooks/useApiCall';
import * as session from '../../../utils/session';
import Loader from '../../../components/Loader/Loader';
import { redirect } from '../../../utils/redirect';
import { resetSession } from '../../../utils/session';
import { redirectURLStructure } from '../../../utils/redirectURLStructure';
import checkUserRole from '../../../utils/checkUserRole';
import ErrorResponse from '../../../components/ErrorResponse/ErrorResponse';
import RegAlertModal from '../../../components/AlertModal/AlertModal';
import routes from '../../../config/routes';
import URL from '../../../utils/url';

const CallBackGoogle = () => {
  const navigate = useNavigate();
  const [params, setParams] = useState(null);
  const [showRegModal, setShowRegModal] = useState(false);
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.hash);
  const idToken = urlSearchParams.get('id_token');

  const { loading, data, error, success } = useApiCall(
    params,
    user.loginGoogle
  );

  useEffect(() => {
    if (idToken) {
      setParams({
        googleAccessToken: idToken,
        referrer: redirectURLStructure('oneTap'),
      });
      resetSession();
    }
  }, [idToken]);

  useEffect(() => {
    if (success) {
      if (checkUserRole(data.data.user)) {
        session.storeSession(data.data);
        if (window.location?.href?.includes('doctoroncall.com.my')) {
          window.smartech(
            'create',
            'ADGMOT35CHFLVDHBJNIG50K969Q1L02D392144T1IJBB24T6SG2G'
          );
          window.smartech('register', 'bb968d0bcc5eae0646a8a3043059261e');
          window.smartech('identify', data.data.user.email);
        } else {
          window.smartech(
            'create',
            'ADGMOT35CHFLVDHBJNIG50K969Q1L02D392144T1IJBB24T6SG2G'
          );
          window.smartech('register', '48488df56ef489c753cd578ebbe5312b');
          window.smartech('identify', data.data.user.email);
        }
        window.smartech(
          'dispatch',
          `${
            data.data.user?.register && data.data.user?.register === true
              ? 'signup'
              : 'signin'
          }`,
          {
            email: data.data.user.email,
          }
        );
        redirect();
      } else {
        window.location = `${session.getGoogleBtnUri()}?error=permission-denied`;
      }
    }
  }, [success]);

  useEffect(() => {
    /* eslint-disable no-console */
    error && console.error(error);
    if (error) {
      const err = error;
      const errorResponse = ErrorResponse(err);
      if (errorResponse === 'not-registered') {
        // setShowRegModal(true);
        navigate(
          new URL(routes.register)
            .appendParams({
              ...(error?.email ? { email: error?.email } : {}),
              is_google_signup: error?.email ? 'approved' : 'cancelled',
            })
            .getUrl()
        );
      }
    }
  }, [error]);

  return loading || success ? (
    <Loader hasOverlay={true} width={50} height={50} />
  ) : (
    <div>
      {showRegModal && (
        <RegAlertModal
          onCancel={() => {
            // setShowRegModal(false);
            navigate(
              new URL(routes.home)
                .appendParams({
                  // email: 'my-email@gmail.com',
                })
                .getUrl()
            );
          }}
          onConfirm={() => {
            navigate(
              new URL(routes.register)
                .appendParams({
                  ...(error?.email ? { email: error?.email } : {}),
                  is_google_signup: error?.email ? 'approved' : 'cancelled',
                })
                .getUrl()
            );
          }}
          width="328px"
          height="196px"
          title=""
        />
      )}
    </div>
  );
};

export default CallBackGoogle;
