import React from 'react';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';
import InputLabel from '../InputLabel/InputLabel';
import { inputTheme } from '../Input/Input';

const classNames = require('classnames');

export const OTPInputTheme = Object.freeze({
  inputStyle: {
    width: '36px',
    height: '40px',
    margin: '0px',
    fontSize: '14px',
    borderRadius: '8px',
    border: '1px solid #DBDBDB',
  },
  errorStyle: {
    width: '36px',
    height: '40px',
    margin: '0px',
    fontSize: '14px',
    borderRadius: '8px',
    border: '1px solid red',
  },
  focusStyle: {
    border: '1px solid #00CC99',
    outline: 'none',
  },
  disabledStyle: {
    background: '#dddddd',
  },
  containerStyle: {
    justifyContent: '',
  },
});

function OTPInput(props) {
  const {
    separator,
    placeholder,
    inputStyle,
    errorStyle,
    focusStyle,
    disabledStyle,
    containerStyle,
    numInputs,
    isInputNum,
    shouldAutoFocus,
    isDisabled,
    isInputSecure,
    labelText,
    inputLabelHeight,
    inputLabelThemeClasses,
    bottomText,
    formik,
    name,
  } = props;

  const { value } = formik.getFieldProps(name);
  const { errors, touched } = formik;

  return (
    <div>
      <InputLabel
        theme={classNames(
          'text-base text-gray-400 font-light pb-2 leading-5',
          inputLabelThemeClasses
        )}
        label={labelText}
        height={inputLabelHeight}
      />
      <OtpInput
        inputStyle={inputStyle}
        errorStyle={errorStyle}
        focusStyle={isDisabled ? {} : focusStyle}
        hasErrored={errors[name] && touched[name]}
        placeholder={placeholder}
        disabledStyle={disabledStyle}
        value={value}
        onChange={(val) => formik.setFieldValue(name, val)}
        numInputs={numInputs}
        separator={<span style={{ margin: '0 6px' }}>{separator}</span>}
        isInputNum={isInputNum}
        shouldAutoFocus={shouldAutoFocus}
        isDisabled={isDisabled}
        isInputSecure={isInputSecure}
        containerStyle={containerStyle}
      />
      {errors[name] && touched[name] && (
        <div className={inputTheme.error}>{errors[name]}</div>
      )}
      <div>{bottomText}</div>
    </div>
  );
}

export default OTPInput;

OTPInput.propTypes = {
  separator: PropTypes.string,
  numInputs: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  shouldAutoFocus: PropTypes.bool,
  isInputSecure: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  inputStyle: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  errorStyle: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  focusStyle: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  containerStyle: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  disabledStyle: PropTypes.objectOf(PropTypes.any),
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInputNum: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  inputLabelHeight: PropTypes.string.isRequired,
  inputLabelThemeClasses: PropTypes.string.isRequired,
  bottomText: PropTypes.element.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
};

OTPInput.defaultProps = {
  placeholder: '',
  separator: '',
  isDisabled: false,
  isInputSecure: false,
  isInputNum: false,
  containerStyle: OTPInputTheme.containerStyle,
  inputStyle: OTPInputTheme.inputStyle,
  errorStyle: OTPInputTheme.errorStyle,
  focusStyle: OTPInputTheme.focusStyle,
  disabledStyle: OTPInputTheme.disabledStyle,
  shouldAutoFocus: false,
};
