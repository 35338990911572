import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { user } from '@doctoroncallcom/api';
import { parsePhoneNumber } from 'react-phone-number-input';
import googleRecaptchaFunc from '../../utils/recaptcha';
import * as session from '../../utils/session';
import { redirect } from '../../utils/redirect';
import useApiCall from '../../hooks/useApiCall';
import RegAlertModal from '../../components/AlertModal/AlertModal';
import Loader from '../../components/Loader/Loader';
import Input, { inputTheme } from '../../components/Input/Input';
import Header from '../../components/Layout/Header';
import Layout from '../../components/Layout/Layout';
import InputLabel, {
  inputLabelTheme,
} from '../../components/InputLabel/InputLabel';
import Button, { buttonTheme } from '../../components/Button/Button';
import { errorHandler } from '../../components/errorHandler/errorHandler';
import URL from '../../utils/url';
import routes from '../../config/routes';
import { validateEmail, validateMobile } from '../../utils/validation';
import ErrorResponse from '../../components/ErrorResponse/ErrorResponse';
import ErrorDisplayInline from '../../components/ErrorDisplayInline/ErrorDIsplayInline';
import { REACT_APP_PASSWORD_REGEX } from '../../utils/configUrls';
import GoBackAndInfo from '../../components/BackAndInfo/BackAndInfo';
import GoogleLogin from '../../components/GoogleLogin';
import * as configUrls from '../../utils/configUrls';
import checkUserRole from '../../utils/checkUserRole';

function LoginWithEmail() {
  const navigate = useNavigate();
  const redirectUri = session.getRedirectUri() || '';
  const [params, setParams] = useState(null);
  const [cc, setCc] = useState('');
  const [recaptchaError, setRecaptchaError] = useState('');
  const [recaptchaLoading, setRecaptchaLoading] = useState(false);
  const countriesCodeArr = ['+60', '+61', '+62', '+65'];

  const { loading, data, error, success } = useApiCall(params, user.login);
  const [showRegModal, setShowRegModal] = useState(false);
  const [showGoogleBtn, setShowGoogleBtn] = useState(true);

  const validate = (values) => {
    const errors = {};
    let emailError = false;
    let phoneError = false;
    // Email Test
    if (!validateEmail(values.email)) {
      emailError = true;
    }
    // Phone Test
    /* eslint-disable */
    if (emailError && values.email?.length > 3) {
      if (values.email.includes('+6')) {
        const validation = validateMobile(values.email);
        if (validation) {
          setCc('+' + parsePhoneNumber(values.email).countryCallingCode);
        } else {
          phoneError = true;
        }
      } else {
        let matched = false;
        for (let i = 0; i < countriesCodeArr.length; i++) {
          if (validateMobile(countriesCodeArr[i] + values.email)) {
            setCc(countriesCodeArr[i]);
            matched = true;
            return;
          }
        }
        if (matched === false) {
          phoneError = true;
        }
      }
    } else {
      setCc('');
    }

    if (emailError && phoneError) {
      errors.email = 'Please provide a valid Mobile number or Email address.';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      generalError: '',
    },

    validationSchema: Yup.object().shape({
      email: Yup.string().required('Required*'),
      password: Yup.string()
        .min(8, 'At least 8 characters.')
        .required('Required*')
        .matches(
          REACT_APP_PASSWORD_REGEX,
          'Password should contain a special character.'
        ),
    }),
    validate,
    onSubmit: (values) => {
      setRecaptchaLoading(true);
      googleRecaptchaFunc()
        .then((token) => {
          setRecaptchaLoading(false);
          if (validateEmail(values.email)) {
            setParams({
              email: values.email.toLowerCase(),
              type: 'EMAIL',
              password: values.password,
              recaptchaToken: token,
            });
          } else {
            setParams({
              phoneNumber: values.email.includes('+6')
                ? parsePhoneNumber(values.email).nationalNumber
                : parsePhoneNumber(cc + values.email).nationalNumber,
              type: 'SMS',
              countryCode: cc,
              password: values.password,
              recaptchaToken: token,
            });
          }
        })
        .catch((errToken) => {
          setRecaptchaLoading(false);
          setRecaptchaError(errToken);
        });
    },
  });

  useEffect(() => {
    if (success) {
      if (checkUserRole(data.data.user)) {
        session.storeSession(data.data);
        try {
          if (window.location?.href?.includes('doctoroncall.com.my')) {
            window.smartech(
              'create',
              'ADGMOT35CHFLVDHBJNIG50K969Q1L02D392144T1IJBB24T6SG2G'
            );
            window.smartech('register', 'bb968d0bcc5eae0646a8a3043059261e');
            window.smartech('identify', data.data?.user?.email);
          } else {
            window.smartech(
              'create',
              'ADGMOT35CHFLVDHBJNIG50K969Q1L02D392144T1IJBB24T6SG2G'
            );
            window.smartech('register', '48488df56ef489c753cd578ebbe5312b');
            window.smartech('identify', data.data?.user?.email);
          }
          window.smartech('dispatch', 'signin', {
            email: data.data?.user?.email,
          });
        } catch (e) {
          /* eslint-disable */
          console.log(`Netcore error: ${e}`);
        }
        redirect();
      } else {
        formik.setErrors({
          generalError: 'Permission Denied.',
        });
      }
    }
  }, [success]);

  useEffect(() => {
    /* eslint-disable no-console */
    if (error || recaptchaError) {
      formik.setErrors(errorHandler(error));
      const err = error || recaptchaError;
      if (err === 401) {
        formik.setErrors({ password: 'invalid credentials' });
      }
      const errorResponse = ErrorResponse(err);
      if (errorResponse === 'not-registered') {
        setShowRegModal(true);
      } else {
        formik.setErrors({
          generalError: errorResponse,
        });
      }
      setParams(null);
    }
  }, [error, recaptchaError]);

  useEffect(() => {
    const urlParams = new URL().getParams();
    if (urlParams.error && urlParams.error === 'permission-denied') {
      formik.setErrors({
        generalError: 'Permission Denied.',
      });
    } else {
      session.setGoogleBtnUri(
        `${window.location.origin}${window.location.pathname}`
      );
    }

    const redirectUri = session.getRedirectUri() || '';
    if (redirectUri.includes('docpod')) {
      setShowGoogleBtn(false);
    }
  }, []);

  return (
    <Layout>
      <div className="flex flex-col items-center h-full w-full">
        <GoBackAndInfo showBack={true} />
        <div className="w-full justify-center flex-col flex items-center">
          <Header />
          <main className="flex justify-center items-center pt-10 flex-col px-5 md:pt-5 lg:w-3/5">
            <form onSubmit={formik.handleSubmit} className="w-full">
              <div className="pb-2 w-full">
                <Input
                  theme={inputTheme.plain}
                  label={
                    <InputLabel
                      label="Enter Mobile Number or Email"
                      theme={inputLabelTheme.default}
                      height="20px"
                    />
                  }
                  type="text"
                  disabled={false}
                  readOnly={false}
                  formik={formik}
                  name="email"
                  id="email"
                  height="41px"
                  width="100%"
                  placeholder="+60123456789 / johndoe@gmail.com"
                />
              </div>
              <div className="pb-2 w-full">
                <Input
                  theme={inputTheme.plain}
                  label={
                    <InputLabel
                      label="Password"
                      theme={inputLabelTheme.default}
                      height="20px"
                    />
                  }
                  type="password"
                  disabled={false}
                  readOnly={false}
                  formik={formik}
                  name="password"
                  id="password"
                  height="41px"
                  width="100%"
                  placeholder="My Password"
                />
              </div>
              <div className="pt-0 pb-4 font-light text-left w-full">
                <p>
                  Forgot password?{' '}
                  <span
                    className="text-theme-primary cursor-pointer leading-6"
                    onClick={() => navigate('/forgot-password')}
                  >
                    Reset Password
                  </span>
                </p>
              </div>
              <div className="flex md:flex-col flex-col-reverse w-full">
                <div className="py-3 w-full ">
                  <Button
                    type="submit"
                    label="Sign In"
                    primary={true}
                    theme={buttonTheme.primary}
                    isLoading={loading || recaptchaLoading}
                    loader={<Loader height={15} width={15} color="white" />}
                    disabled={loading || recaptchaLoading}
                    width="100%"
                    height="41px"
                  />
                </div>
              </div>
              {formik.errors?.generalError ? (
                <>
                  <ErrorDisplayInline error={formik.errors.generalError} />
                  <br />
                </>
              ) : null}
            </form>
            <div className="pb-2 w-full text-center text-theme-gray font-light">
              Or
            </div>
            <div className="pt-0 pb-2 w-full ">
              <Button
                type="submit"
                label="Continue with SMS"
                primary={false}
                theme={buttonTheme.secondary}
                /* eslint-disable */
                disabled={loading}
                width="100%"
                height="41px"
                icon="./assets/icons/phone-icon.svg"
                iconPosition="left"
                onClick={() => navigate('/')}
              />
            </div>
            {showGoogleBtn && (
              <div className="py-1 w-full ">
                <GoogleLogin
                  clientId={configUrls.REACT_APP_GOOGLE_CLIENT_ID}
                  redirectUri={`${window.location.origin}${routes.callbackGoogle}`}
                />
              </div>
            )}
            <div className="pt-3 font-normal text-gray-600 text-center">
              <p>
                By Clicking on Sign In, you agree to our{' '}
                <span className="text-theme-primary cursor-pointer leading-6	">
                  <a
                    className="text-theme-primary cursor-pointer leading-6"
                    href="https://www.doctoroncall.com.my/termsOfService"
                  >
                    Terms & Conditions
                  </a>
                </span>
              </p>
            </div>
          </main>
        </div>
        <div className="flex-1"> </div>
        <div className="flex-1"> </div>
      </div>
      {showRegModal && (
        <RegAlertModal
          onCancel={() => setShowRegModal(false)}
          onConfirm={() => {
            navigate(new URL(routes.register).getUrl());
          }}
          width="328px"
          height="196px"
          title=""
        />
      )}
    </Layout>
  );
}

export default LoginWithEmail;
