import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

const classNames = require('classnames');

export const ModalProperties = Object.freeze({
  title: 'text-lg font-bold	leading-6',
});

function Modal(props) {
  const { width, height, title, toggleModal } = props;
  return ReactDOM.createPortal(
    <>
      <div
        className="fixed z-10 h-screen w-full top-0 left-0 "
        style={{ background: 'rgba(60, 60, 60, 0.5)' }}
        onClick={toggleModal}
      />
      <div
        style={{ width, height }}
        className="bg-white z-50 fixed p-5 rounded-3xl top-2/4	left-2/4 transform -translate-x-2/4	 -translate-y-2/4"
      >
        <div className="flex justify-between">
          <div className={classNames(ModalProperties.title)}>{title}</div>
          <div>
            <img
              src="/assets/icons/cross_icon.svg"
              alt="close button"
              onClick={toggleModal}
              className="cursor-pointer"
            />
          </div>
        </div>
        {props.children}
      </div>
    </>,
    document.body
  );
}

export default Modal;
Modal.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  title: '',
};
