import { getRedirectUri } from './session';

export const redirectURLStructure = (data) => {
  let rd = '';
  if (getRedirectUri()) {
    rd = new URL(getRedirectUri());
  }
  if (rd && rd.pathname) {
    if (!data) {
      rd.pathname.slice(-1) === '/' && rd.pathname !== '/'
        ? rd.pathname.slice(0, -1)
        : rd.pathname;
    } else {
      rd = 'oneTap';
    }
  } else {
    rd = '/';
  }
  return rd;
};
