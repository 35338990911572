const routes = {
  home: '/',
  login: '/login',
  logout: '/logout',
  loginEmail: '/login-email',
  register: '/register',
  callbackGoogle: '/callback/google',
  loginPhone: '/login-phone',
  loginPhoneVerify: '/login-verify',
  corporateLogin: '/corporate-login',
  corporateVerify: '/corporate-verify',
  corporatePasswordSetup: '/corporate-password',
  loginVerificationMethod: '/login-verification-options',
  forgotPassword: '/forgot-password',
  forgotPasswordVerify: '/change-password',
  resetPassword: '/reset-password',
  patientDashboard: '/patient-dashboard',
  completeProfile: '/complete-profile',
};

export default routes;
