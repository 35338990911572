export const REACT_APP_GOOGLE_CLIENT_ID =
  '136992259293-mgpfegqbm04avkppb7v4rhac5oeu0t8i.apps.googleusercontent.com';
export const REACT_APP_CLIENT_SECRET = 'n5oHpE91NkE4_2-Z7xf5QeVE';
export const REACT_APP_RECAPTCHA_SITE_KEY =
  '6LdUJz0eAAAAALp7uX4kHSVxl4jn6FMHMEgAaViE';
export const REACT_APP_DEFAULT_URL = window.location.href.includes(
  'doctoroncall.com.my'
)
  ? 'https://www.doctoroncall.com.my'
  : 'https://www.doctoroncall.com';
export const REACT_APP_SENTRY_DNS =
  'https://293df4a4d9de4826a022672d4aa09c24@o1080961.ingest.sentry.io/6090046';
export const REACT_APP_PASSWORD_REGEX = /^(?=.*[!@#$%^&*])/;
