import React, { useEffect, useState } from 'react';
import * as apiService from '@doctoroncallcom/api';
import { useNavigate } from 'react-router-dom';
import Button, { buttonTheme } from '../../components/Button/Button';
import Layout from '../../components/Layout/Layout';
import useApiCall from '../../hooks/useApiCall';
import URL from '../../utils/url';
import Loader from '../../components/Loader/Loader';
import routes from '../../config/routes';
import googleRecaptchaFunc from '../../utils/recaptcha';
import ErrorResponse from '../../components/ErrorResponse/ErrorResponse';
import ErrorDisplayInline from '../../components/ErrorDisplayInline/ErrorDIsplayInline';

function LoginVerificationOptions() {
  const navigate = useNavigate();
  const securePhone = (phone) => phone.replace(/\d(?=[0-9]\d{1})/gm, '*');
  const urlParams = new URL().getParams();

  const [params, setParams] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState('');
  const [recaptchaLoading, setRecaptchaLoading] = useState(false);
  const [generalError, setGeneralError] = useState('');

  const { loading, data, error, success } = useApiCall(
    params,
    apiService.otp.code
  );

  function setType(type) {
    setRecaptchaLoading(true);
    googleRecaptchaFunc()
      .then((token) => {
        setRecaptchaLoading(false);
        setParams({
          type,
          phoneNumber: urlParams.phone.split(urlParams.countryCode)[1],
          countryCode: urlParams.countryCode,
          recaptchaToken: token,
        });
      })
      .catch((errToken) => {
        setRecaptchaLoading(false);
        setRecaptchaError(errToken);
      });
  }

  useEffect(() => {
    if (success) {
      setGeneralError('');
      const url = new URL(routes.loginPhoneVerify)
        .appendParams({
          sessionId: data.data.sessionId,
          type: params.type,
        })
        .getUrl();
      navigate(url);
    }
  }, [success]);

  useEffect(() => {
    if (error || recaptchaError) {
      setParams(null);
      const err = error || recaptchaError;
      const errorResponse = ErrorResponse(err);
      setGeneralError(errorResponse);
    }
  }, [error || recaptchaError]);

  return (
    <Layout>
      <div className="w-full items-center h-full flex-col flex pt-20">
        <main className="flex justify-center items-center pt-10 flex-col lg:w-3/5 px-5">
          <div className="w-full text-theme-gray text-center font-normal">
            <p>
              Select one of the methods below to send verification code to{' '}
              {securePhone(`${urlParams.phone}`)}
            </p>
          </div>
          <div className="py-3 w-full">
            <Button
              type="button"
              onClick={() => setType('WHATSAPP')}
              label="WhatsApp"
              primary={false}
              theme={buttonTheme.secondary}
              isLoading={
                params?.type === 'WHATSAPP' && (loading || recaptchaLoading)
              }
              disabled={loading || recaptchaLoading}
              loader={<Loader height={15} width={15} />}
              width="100%"
              height="41px"
              icon="./assets/icons/whatsapp-icon.svg"
              iconPosition="center"
            />
          </div>
          <div className="py-3 w-full ">
            <Button
              onClick={() => setType('SMS')}
              type="button"
              label="Send SMS"
              primary={false}
              theme={buttonTheme.secondary}
              isLoading={
                params?.type === 'SMS' && (loading || recaptchaLoading)
              }
              disabled={loading || recaptchaLoading}
              loader={<Loader height={15} width={15} />}
              width="100%"
              height="41px"
              icon="./assets/icons/sms-icon.svg"
              iconPosition="center"
            />
          </div>
          <div className="py-3 w-full ">
            <Button
              onClick={() => setType('CALL')}
              type="button"
              label="Make a phone call"
              primary={false}
              isLoading={
                params?.type === 'CALL' && (loading || recaptchaLoading)
              }
              disabled={loading || recaptchaLoading}
              loader={<Loader height={15} width={15} />}
              theme={buttonTheme.secondary}
              width="100%"
              height="41px"
              icon="./assets/icons/phone-icon.svg"
              iconPosition="center"
            />
          </div>
          {generalError ? <ErrorDisplayInline error={generalError} /> : null}
        </main>
      </div>
    </Layout>
  );
}

export default LoginVerificationOptions;
