import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

export const buttonTheme = Object.freeze({
  primary: 'bg-blue-primary text-white rounded-md p-1 font-normal',
  secondary:
    'bg-white text-black rounded-md border border-solid text-theme-gray p-1',
  disabled: 'bg-gray-200 text-black rounded-md p-1 cursor-not-allowed',
  white: 'bg-white text-red-400 rounded-md p-1 border-0',
});

export const iconButtonPosition = Object.freeze({
  left: 'justify-between px-2',
  center: 'justify-center gap-2',
});

// iconPosition prop must be "left or center"
// icon prop must have "url" of icon or "path" of icon

function Button(props) {
  const {
    label,
    theme,
    primary,
    width,
    minWidth,
    height,
    type,
    icon,
    iconPosition,
    disabled,
    onClick,
    loader,
    isLoading,
  } = props;
  return (
    <div style={{ height, width, minWidth }}>
      <button
        style={{ width: '100%', height: '100%' }}
        type={type === 'button' ? 'button' : 'submit'}
        className={classNames(
          `flex items-center leading-none ${disabled && 'text-gray-500'}`,
          iconButtonPosition.center,
          {
            [theme]: primary === true && disabled === false,
            [buttonTheme.secondary]: primary === false && disabled === false,
            [iconButtonPosition.left]:
              iconPosition.length > 0 &&
              icon.length > 0 &&
              iconPosition === 'left',
            [buttonTheme.disabled]: disabled === true,
          }
        )}
        disabled={disabled}
        onClick={onClick}
        // {...props}
      >
        {icon.length > 0 && <img src={icon} alt="icon" />}
        <span>{!isLoading ? label : loader}</span>
        {icon && <span />}
      </button>
    </div>
  );
}

export default Button;

Button.propTypes = {
  primary: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  type: PropTypes.string.isRequired,
  icon: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  minWidth: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  iconPosition: PropTypes.string,
  disabled: PropTypes.bool,
  loader: PropTypes.element,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  primary: false,
  onClick: undefined,
  theme: buttonTheme.primary,
  icon: '',
  iconPosition: iconButtonPosition.left,
  disabled: false,
  loader: undefined,
  isLoading: false,
  minWidth: '30px',
};
