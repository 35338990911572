import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

export const inputLabelTheme = Object.freeze({
  default: 'inline-block text-gray-700 text-xs font-light leading-5',
});

function InputLabel(props) {
  const { label, theme, height, width } = props;
  return (
    <div className={classNames(theme)} style={{ height, width }}>
      {label}
    </div>
  );
}

InputLabel.defaultProps = {
  width: undefined,
  height: undefined,
};

InputLabel.propTypes = {
  label: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default InputLabel;
