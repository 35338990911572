import React from 'react';
import PropTypes from 'prop-types';
import { inputTheme } from '../Input/Input';

const classNames = require('classnames');

export default function Dropdown(props) {
  const {
    name,
    id,
    disabled,
    multiple,
    options,
    onChange,
    required,
    size,
    onBlur,
    onFocus,
    className,
    style,
    value,
    height,
    width,
    label,
    formik,
  } = props;
  const { errors, touched } = formik;
  return (
    <div style={{ height, width }}>
      {label}
      <select
        name={name}
        id={id}
        disabled={disabled}
        multiple={multiple}
        required={required}
        size={size}
        className={classNames(className)}
        style={style}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        {...formik.getFieldProps(name)}
      >
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      {errors[name] && touched[name] && (
        <div className={inputTheme.error}>{errors[name]}</div>
      )}
    </div>
  );
}
Dropdown.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  size: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.objectOf(PropTypes.any),
  label: PropTypes.element,
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.objectOf(PropTypes.any).isRequired,
};
Dropdown.defaultProps = {
  width: undefined,
  height: undefined,
  name: '',
  id: '',
  disabled: false,
  multiple: false,
  required: false,
  size: '',
  onBlur: undefined,
  onFocus: undefined,
  className: '',
  style: {},
  label: undefined,
  value: '',
};
