import { API } from '@doctoroncallcom/api';
import * as session from '../utils/session';

const api = new API(
  window.location.href.includes('auth.dev') ||
  window.location.href.includes('localhost')
    ? 'dev'
    : 'prod'
);
api.setCamelCaseTransformer(true);
const user = session.getSession();
if (user) {
  const token = user?.token || user?.user?.token;
  api.setAuthorizationToken(token);
}

export default api;
