import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import mykad from 'mykad';
import { user } from '@doctoroncallcom/api';
import * as apiService from '@doctoroncallcom/api';
import classNames from 'classnames';
import moment from 'moment';
import * as session from '../../utils/session';
import { redirect } from '../../utils/redirect';
import useApiCall from '../../hooks/useApiCall';
import googleRecaptchaFunc from '../../utils/recaptcha';
import Loader from '../../components/Loader/Loader';
import Input, { inputTheme } from '../../components/Input/Input';
import Layout from '../../components/Layout/Layout';
import InputLabel, {
  inputLabelTheme,
} from '../../components/InputLabel/InputLabel';
import Button, { buttonTheme } from '../../components/Button/Button';
import Dropdown from '../../components/Dropdown/Dropdown';
import CalendarInput from '../../components/Calendar/Calendar';
import CustomInput from '../../components/Calendar/CustomInput';
import AlertCard, { alertCardTheme } from '../../components/AlertCard';
import OTPModal from '../../components/OTPModal/OTPModal';
import { errorHandler } from '../../components/errorHandler/errorHandler';
import URL from '../../utils/url';
import PhoneNumberInput from '../../components/PhoneInput/PhoneInput';
import VerificationMethodModal from '../../components/VerificationMethodModal/OTPModal';
import ErrorResponse from '../../components/ErrorResponse/ErrorResponse';
import ErrorDisplayInline from '../../components/ErrorDisplayInline/ErrorDIsplayInline';
import { REACT_APP_PASSWORD_REGEX } from '../../utils/configUrls';
import GoBackAndInfo from '../../components/BackAndInfo/BackAndInfo';
import decodeToken from '../../components/DecodeToken/DecodeToken';
import { redirectURLStructure } from '../../utils/redirectURLStructure';
import checkUserRole from '../../utils/checkUserRole';
import RegAlertModal from '../../components/AlertModal/AlertModal';

const date = new Date();
const mapIcToPassport = (ic) => ({
  [mykad.isValid(ic) ? 'ic' : 'passport']: ic,
});
const GENDERS = [
  { value: '', name: 'Select Gender' },
  { value: 'male', name: 'Male' },
  { value: 'female', name: 'Female' },
];
function CreateAccount() {
  const errorInfoRef = useRef();
  const navigate = useNavigate();
  const urlParams = new URL().getParams();
  const [params, setParams] = useState(null);
  const [otpParams, setOtpParams] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState('');
  const [recaptchaLoading, setRecaptchaLoading] = useState(false);
  const otpApi = useApiCall(otpParams, apiService.otp.code);

  const { loading, data, error, success } = useApiCall(params, user.register);
  const [showRegModal, setShowRegModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showVerificationOption, setShowVerificationOption] = useState(false);
  const [otp, setOtp] = useState('');
  const [resendCode, setResendCode] = useState(false);
  const [counter, setCounter] = useState(0);
  const [pnChange, setPnChange] = useState({
    pn: '',
    showPhChangeInfo: false,
  });
  const [partnerSignUp, setPartnerSignUp] = useState({
    isPartnerSignUp: false,
    partnerId: '',
    partnerRedirectUrl: '',
  });

  const changeOtpParams = (values, type, token) => {
    setOtpParams({
      countryCode: `+${values.countryCode}`,
      phoneNumber: values.phoneNumber.replace(`+${values.countryCode}`, ''),
      type,
      recaptchaToken: token,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: urlParams?.email || '',
      passport: '',
      password: '',
      gender: '',
      dob: date,
      code: urlParams?.code || '',
      country: urlParams?.countryC || 'MY',
      countryCode: urlParams?.countryCode || '',
      phoneNumber: urlParams?.phoneNumber || '',
      sessionId: urlParams?.sessionId || '',
      partnerId: null,
      generalError: '',
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required*'),
      email: Yup.string().email('Invalid email format.').required('Required*'),
      code: Yup.string(),
      phoneNumber: Yup.string().required('Required*'),
      sessionId: Yup.string(),
      passport: Yup.string().required('Required*'),
      gender: Yup.string(),
      ...(urlParams?.is_google_signup !== 'approved'
        ? {
            password: Yup.string()
              .min(8, 'At least 8 characters.')
              .required('Required*')
              .matches(
                REACT_APP_PASSWORD_REGEX,
                'Password should contain a special character.'
              ),
          }
        : {}),
      // password: Yup.string()
      // .min(8, 'At least 8 characters.')
      // .required('Required*')
      // .matches(
      //   REACT_APP_PASSWORD_REGEX,
      //   'Password should contain a special character.'
      // ),
      dob: Yup.date().nullable().required('Required*'),
    }),
    onSubmit: (values) => {
      setRecaptchaLoading(true);
      googleRecaptchaFunc()
        .then((token) => {
          setRecaptchaLoading(false);
          if (!values.sessionId && !values.code) {
            setPnChange({
              pn: values.phoneNumber,
              showPhChangeInfo: false,
            });
            changeOtpParams(values, 'SMS', token);
            setResendCode(true);
            setCounter(60);
            return;
          }
          if (
            (values.sessionId && !values.code) ||
            (values.code && !showOtpModal)
          ) {
            if (values.phoneNumber === pnChange.pn) {
              setShowOtpModal(true);
              return;
            }
            if (values.phoneNumber !== pnChange.pn && counter === 0) {
              setPnChange({
                pn: values.phoneNumber,
                showPhChangeInfo: false,
              });
              changeOtpParams(values, 'SMS', token);
              formik.values.code = '';
              setResendCode(true);
              setCounter(60);
              setShowOtpModal(true);
              return;
            }
            if (values.phoneNumber !== pnChange.pn && counter > 0) {
              setPnChange({
                ...pnChange,
                showPhChangeInfo: true,
              });
              return;
            }
          }
          const userData = {
            ...values,
            email: values.email.toLowerCase(),
            dob: moment(values.dob).format('YYYY-M-D'),
            identification: mapIcToPassport(values.passport),
            phoneNumber: values.phoneNumber.replace(
              `+${values.countryCode}`,
              ''
            ),
            recaptchaToken: token,
            referrer: redirectURLStructure(''),
          };
          setParams(userData);
        })
        .catch((errToken) => {
          setRecaptchaLoading(false);
          setRecaptchaError(errToken);
        });
    },
  });

  useEffect(() => {
    if (formik.values.passport?.length === 12) {
      if (mykad.isValid(formik.values.passport)) {
        const format = mykad.format(formik.values.passport);
        const mykadData = mykad.parse(formik.values.passport);
        mykadData.format = format;
        formik.values.dob = mykadData.birthDate;
        formik.values.gender = mykadData.gender;
      }
    }
  }, [formik.values.passport]);

  useEffect(() => {
    if (success) {
      if (checkUserRole(data.data.user)) {
        setShowOtpModal(false);
        if (window.location?.href?.includes('doctoroncall.com.my')) {
          window.smartech(
            'create',
            'ADGMOT35CHFLVDHBJNIG50K969Q1L02D392144T1IJBB24T6SG2G'
          );
          window.smartech('register', 'bb968d0bcc5eae0646a8a3043059261e');
          window.smartech('identify', formik.values.email);
        } else {
          window.smartech(
            'create',
            'ADGMOT35CHFLVDHBJNIG50K969Q1L02D392144T1IJBB24T6SG2G'
          );
          window.smartech('register', '48488df56ef489c753cd578ebbe5312b');
          window.smartech('identify', formik.values.email);
        }
        window.smartech('dispatch', 'signup', {
          email: formik.values.email,
          mobile: formik.values.phoneNumber,
        });
        session.storeSession(data.data);
        if (partnerSignUp.partnerRedirectUrl) {
          window.location = partnerSignUp.partnerRedirectUrl;
        } else {
          redirect();
        }
      } else {
        formik.setErrors({
          generalError: 'Permission Denied.',
        });
      }
    }
  }, [success]);

  const onChange = (val) => {
    setOtp(val);
  };

  const onResend = (type) => {
    const otpType = type || otpParams?.type || 'SMS';
    googleRecaptchaFunc()
      .then((token) => {
        setOtpParams({
          countryCode: `+${formik.values.countryCode}`,
          phoneNumber: formik.values.phoneNumber.replace(
            `+${formik.values.countryCode}`,
            ''
          ),
          type: otpType,
          recaptchaToken: token,
        });
      })
      .catch((errToken) => {
        setRecaptchaError(errToken);
      });
    setResendCode(true);
    setCounter(60);
  };
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setResendCode(false);
      setPnChange({
        ...pnChange,
        showPhChangeInfo: false,
      });
    }
    return () => clearInterval(timer);
  }, [counter]);

  const executeScroll = () => errorInfoRef.current.scrollIntoView();

  useEffect(() => {
    if (error || recaptchaError || otpApi.error) {
      const err = error || recaptchaError || otpApi.error;
      formik.setErrors(errorHandler(err));
      if (err.code === 409) {
        executeScroll();
      } else if (err.code === 401) {
        formik.setErrors({
          code: 'Incorrect code entered.',
        });
      } else {
        formik.setErrors({
          generalError: ErrorResponse(err),
        });
      }
    }
  }, [error, recaptchaError, otpApi.error]);

  useEffect(() => {
    if (otpApi.success) {
      setShowOtpModal(true);
      formik.setFieldValue('sessionId', otpApi.data.data.sessionId);
    }
  }, [otpApi.success]);

  const OTPBottomText = () =>
    resendCode ? (
      <p className="text-base text-gray-800 font-light pt-2 leading-5">
        Please wait{' '}
        <span className={classNames(inputTheme.error, 'text-base')}>
          {counter} seconds
        </span>{' '}
        to resend.
      </p>
    ) : (
      <p className="text-base text-gray-800 font-normal pt-2 leading-5">
        Did not received the code?{' '}
        <span
          className="text-theme-primary cursor-pointer"
          onClick={() => onResend(otpParams.type)}
        >
          Resend
        </span>{' '}
        or try a{' '}
        <span
          className="text-theme-primary cursor-pointer"
          onClick={() => setShowVerificationOption(true)}
        >
          different verification method
        </span>
      </p>
    );

  const secureEmail = (email) => {
    const [name, domain] = email.split('@');
    return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
  };

  useEffect(() => {
    const urlSearchParams = new URL();
    const urlParameters = urlSearchParams.getParams();
    if (urlParameters.token) {
      const tokenData = decodeToken(urlParameters.token);
      if (tokenData) {
        setPartnerSignUp({
          isPartnerSignUp: true,
          partnerId: tokenData.partnerId,
          partnerRedirectUrl: tokenData.redirectUrl,
        });
        formik.setFieldValue('partnerId', tokenData.partnerId);
      }
    }

    if (urlParameters?.is_google_signup === 'approved') {
      setShowRegModal(true);
    }
  }, []);

  useEffect(() => {
    if (error && error.code === 409) {
      setShowOtpModal(false);
    }
  }, [error]);

  return (
    <Layout>
      <div className="w-full items-center h-full flex-col flex overflow-y-auto py-5">
        <GoBackAndInfo showBack={true} />
        <h1 className="text-4xl text-theme-gray font-bold sm:mt-5">
          Create Account
        </h1>
        <main className="flex justify-center items-center pt-10 flex-col px-5 md:pt-5 lg:w-3/5">
          <form onSubmit={formik.handleSubmit} className="w-full">
            <div className="pb-2 w-full">
              <Input
                theme={inputTheme.plain}
                label={
                  <InputLabel
                    label="Full Name"
                    theme={inputLabelTheme.default}
                    height="20px"
                  />
                }
                type="text"
                disabled={false}
                readOnly={false}
                formik={formik}
                name="name"
                id="name"
                height="41px"
                width="100%"
                placeholder=""
              />
            </div>
            <div className="pb-2 w-full">
              <Input
                theme={inputTheme.plain}
                label={
                  <InputLabel
                    label="Email"
                    theme={inputLabelTheme.default}
                    height="20px"
                  />
                }
                type="email"
                disabled={false}
                readOnly={false}
                formik={formik}
                name="email"
                id="email"
                height="41px"
                width="100%"
                placeholder=""
              />
            </div>
            <div className="pb-2 w-full">
              <Input
                theme={inputTheme.plain}
                label={
                  <InputLabel
                    label="NRIC/Passport No."
                    theme={inputLabelTheme.default}
                    height="20px"
                  />
                }
                type="text"
                disabled={false}
                readOnly={false}
                formik={formik}
                name="passport"
                id="passport"
                height="41px"
                width="100%"
                placeholder=""
              />
            </div>
            <div className="pb-2 w-full flex justify-between">
              <CalendarInput
                onBlur={formik.handleBlur}
                formik={formik}
                customInput={<CustomInput />}
                calendarClassName="defaultCalendar"
                name="dob"
                id="dob"
                height="40px"
                width="45%"
                placeholderText="DD-MM-Y"
                label={
                  <InputLabel
                    label="Date of Birth"
                    theme={inputLabelTheme.default}
                    height="20px"
                  />
                }
              />
              <Dropdown
                options={GENDERS}
                formik={formik}
                name="gender"
                id="gender"
                disabled={false}
                multiple={false}
                required={false}
                size=""
                className={inputTheme.plain}
                style={{ height: '42px', paddingLeft: '0px', width: '100%' }}
                height="auto"
                width="45%"
                label={
                  <InputLabel
                    label="Gender"
                    theme={inputLabelTheme.default}
                    height="auto"
                    width="auto"
                  />
                }
              />
            </div>
            {urlParams?.is_google_signup !== 'approved' ? (
              <div className="pb-2 w-full">
                <Input
                  theme={inputTheme.plain}
                  label={
                    <InputLabel
                      label="Password"
                      theme={inputLabelTheme.default}
                      height="20px"
                    />
                  }
                  type="password"
                  disabled={false}
                  readOnly={false}
                  formik={formik}
                  name="password"
                  id="password"
                  height="41px"
                  width="100%"
                  placeholder="My Password"
                />
              </div>
            ) : (
              ''
            )}
            <div className="pb-2 w-full">
              <InputLabel
                label="Mobile Number"
                theme={inputLabelTheme.default}
                height="20px"
              />
              <PhoneNumberInput
                formik={formik}
                width="100%"
                height="41px"
                name="phoneNumber"
                countryInputName="country"
                countryCode="countryCode"
                containerClass="w-full h-full"
                inputStyle={{ width: '100%', height: '100%' }}
                errorText="Please provide a valid mobile number*"
                enableSearch={true}
                disableSearchIcon={true}
                disableDropdown={false}
                disabled={false}
                inputLabelHeight="30px"
                autoFormat={true}
              />
            </div>
            {pnChange.showPhChangeInfo ? (
              <div className="pb-2 w-full">
                <p className="text-base text-gray-800 font-light pt-2 leading-5">
                  Please wait{' '}
                  <span className={classNames(inputTheme.error, 'text-base')}>
                    {counter} seconds
                  </span>{' '}
                  to resubmit the data.
                </p>
              </div>
            ) : null}
            <div className="pt-5 pb-2 w-full ">
              <Button
                type="submit"
                label="Continue"
                primary={true}
                theme={buttonTheme.primary}
                isLoading={
                  loading ||
                  otpApi.loading ||
                  recaptchaLoading ||
                  success === true
                }
                loader={<Loader height={15} width={15} color="white" />}
                disabled={
                  loading ||
                  otpApi.loading ||
                  recaptchaLoading ||
                  success === true
                }
                width="100%"
                height="41px"
              />
            </div>
          </form>
          <div className="pt-3 font-normal text-gray-600 text-center">
            <p>
              By Clicking on Sign In, you agree to our{' '}
              <a
                className="text-theme-primary cursor-pointer leading-6"
                href="https://www.doctoroncall.com.my/termsOfService"
              >
                Terms & Conditions
              </a>
            </p>
          </div>
          {error && error.code === 409 && (
            <div className="w-full pt-3" ref={errorInfoRef}>
              <AlertCard theme={alertCardTheme.danger} width="100%">
                <div className="text-xs leading-5	font-normal">
                  <p className="font-semibold">
                    Looks like you already have an account with us.
                  </p>
                  <br />
                  We found an existing DoctorOnCall account with the email{' '}
                  {secureEmail(formik.values.email)} or phone number{' '}
                  {formik.values.phoneNumber}. You can always <br />
                  <span
                    className="underline cursor-pointer"
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    log in to your existing account.
                  </span>{' '}
                  Or if you don’t have access to your existing account’s
                  email/phone number, please{' '}
                  <a
                    href="https://www.doctoroncall.com.my/help/"
                    className="underline cursor-pointer"
                  >
                    contact us.
                  </a>
                </div>
              </AlertCard>
            </div>
          )}
          {showOtpModal && (
            <OTPModal
              onSubmit={() => {
                formik.submitForm();
              }}
              formik={formik}
              separator="-"
              bottomText={OTPBottomText()}
              toggleModal={() => setShowOtpModal(false)}
              value={otp}
              onChange={onChange}
              isInputNum={true}
              loading={loading || otpApi.loading || recaptchaLoading}
            />
          )}
          {showRegModal ? (
            <RegAlertModal
              onCancel={() => setShowRegModal(false)}
              onConfirm={() => setShowRegModal(false)}
              width="328px"
              height="196px"
              title=""
            />
          ) : (
            ''
          )}
          <br />
          {formik.errors?.generalError ? (
            <ErrorDisplayInline error={formik.errors.generalError} />
          ) : null}
          {showVerificationOption && (
            <VerificationMethodModal
              toggleModal={() => setShowVerificationOption(false)}
              onChange={(type) => {
                onResend(type);
                setShowVerificationOption(false);
              }}
            />
          )}
        </main>
      </div>
    </Layout>
  );
}

export default CreateAccount;
