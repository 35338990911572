import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const alertCardTheme = Object.freeze({
  danger: 'bg-error',
  success: 'bg-success',
  warning: 'bg-warning',
});

function AlertCard({ children, theme, height, width }) {
  return (
    <div
      style={{ height, width }}
      className={classNames(
        'rounded-xl w-full h-full p-5 text-theme-error',
        theme
      )}
    >
      {children}
    </div>
  );
}

AlertCard.propTypes = {
  children: PropTypes.element.isRequired,
  theme: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

AlertCard.defaultProps = {};

export default AlertCard;
