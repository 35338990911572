import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as apiService from '@doctoroncallcom/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import RegAlertModal from '../../components/AlertModal/AlertModal';
import URL from '../../utils/url';
import Button, { buttonTheme } from '../../components/Button/Button';
import { inputTheme } from '../../components/Input/Input';
import Header from '../../components/Layout/Header';
import Layout from '../../components/Layout/Layout';
import OTPInput from '../../components/OTPInput/OTPInput';
import useApiCall from '../../hooks/useApiCall';
import routes from '../../config/routes';
import Loader from '../../components/Loader/Loader';
import googleRecaptchaFunc from '../../utils/recaptcha';
import ErrorResponse from '../../components/ErrorResponse/ErrorResponse';
import ErrorDisplayInline from '../../components/ErrorDisplayInline/ErrorDIsplayInline';

function CorporateVerify() {
  const navigate = useNavigate();
  const [params, setParams] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState('');
  const [recaptchaLoading, setRecaptchaLoading] = useState(false);
  const { loading, data, error, success } = useApiCall(
    params,
    apiService.otp.verify
  );
  const [optParams, setOtpParams] = useState(null);
  const otpApi = useApiCall(optParams, apiService.otp.code);
  const [resendCode, setResendCode] = useState(true);
  const [counter, setCounter] = useState(60);
  const [urlParams, setUrlParams] = useState(null);
  const [info, setInfo] = useState('');
  const [genericType, setGenericType] = useState('simple');

  const formik = useFormik({
    initialValues: {
      code: '',
      generalError: '',
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().required('Required*'),
    }),
    onSubmit: (values) => {
      setRecaptchaLoading(true);
      googleRecaptchaFunc()
        .then((token) => {
          setRecaptchaLoading(false);
          setParams({
            code: values.code,
            sessionId: urlParams.sessionId,
            recaptchaToken: token,
          });
        })
        .catch((errToken) => {
          setRecaptchaLoading(false);
          setRecaptchaError(errToken);
        });
    },
  });

  const onResend = () => {
    setResendCode(true);
    setCounter(60);
    setRecaptchaLoading(true);
    googleRecaptchaFunc()
      .then((token) => {
        setRecaptchaLoading(false);
        if (urlParams.st === 'generic-simple' && urlParams.type === 'EMAIL') {
          setOtpParams({
            email: urlParams.info.toLowerCase(),
            type: urlParams.type,
            recaptchaToken: token,
          });
        } else {
          setOtpParams({
            countryCode: urlParams.countryCode,
            phoneNumber:
              urlParams.st === 'token' ? urlParams.phoneNumber : urlParams.info,
            type: urlParams.type,
            recaptchaToken: token,
          });
        }
      })
      .catch((errToken) => {
        setRecaptchaLoading(false);
        setRecaptchaError(errToken);
      });
  };

  useEffect(() => {
    if (otpApi.success) {
      setUrlParams({
        ...urlParams,
        sessionId: otpApi.data?.data?.sessionId,
      });
    }
  }, [otpApi.success]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setResendCode(false);
    }
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (error || otpApi.error || recaptchaError) {
      const errorResponse = ErrorResponse(
        error || otpApi.error || recaptchaError
      );
      formik.setErrors({
        generalError: errorResponse,
      });
    }
  }, [error, otpApi.error, recaptchaError]);

  useEffect(() => {
    if (success) {
      if (urlParams.type === 'EMAIL') {
        if (urlParams?.otpType) {
          navigate(
            new URL(routes.corporateLogin)
              .replaceParams({
                email: urlParams.info.toLowerCase(),
                emailVerificationStatus: 'verified',
                verificationStatus: 'pending',
                type: 'hardcode',
              })
              .getUrl()
          );
        } else {
          navigate(
            new URL(routes.corporatePasswordSetup)
              .replaceParams({
                ...params,
                sessionId: urlParams.sessionId,
              })
              .appendParams({
                email: data?.data?.email.toLowerCase(),
                emailVerificationStatus: 'verified',
                verificationStatus: 'pending',
                code: formik.values.code,
              })
              .getUrl()
          );
        }
      } else {
        if (urlParams.st === 'token') {
          navigate(
            new URL(routes.corporatePasswordSetup)
              .replaceParams({
                ...params,
                sessionId: urlParams.sessionId,
              })
              .appendParams({
                emailVerificationStatus: 'verified',
                verificationStatus: 'verified',
                info: urlParams?.phoneNumber,
                ...(urlParams.email ? { email: urlParams.email } : {}),
                code: formik.values.code,
              })
              .getUrl()
          );
        }
        if (urlParams.st === 'generic-mobile') {
          navigate(
            new URL(routes.corporatePasswordSetup)
              .replaceParams({
                ...urlParams,
                sessionId: urlParams.sessionId,
              })
              .appendParams({
                emailVerificationStatus: 'pending',
                verificationStatus: 'verified',
                code: formik.values.code,
              })
              .getUrl()
          );
        }
        if (urlParams.st === 'generic-simple') {
          navigate(
            new URL(routes.corporatePasswordSetup)
              .replaceParams({
                ...urlParams,
                sessionId: urlParams.sessionId,
              })
              .appendParams({
                emailVerificationStatus: 'pending',
                verificationStatus: 'verified',
                code: formik.values.code,
              })
              .getUrl()
          );
        }
      }
    }
  }, [success]);

  useEffect(() => {
    const urlParamss = new URL().getParams();
    setUrlParams(urlParamss);
    if (urlParamss.st === 'token' || urlParamss.st === 'generic-mobile') {
      let cc = urlParamss?.countryCode;
      if (cc?.includes('+')) {
        cc = cc.replace('+', '');
      }
      setInfo(`
        +${cc}${
        urlParamss.st === 'token' ? urlParamss.phoneNumber : urlParamss.info
      }
      `);
    }
    if (urlParamss.st === 'generic-simple') {
      /* eslint-disable */
      let cc = urlParamss?.countryCode;
      if (cc?.includes('+')) {
        cc = cc.replace('+', '');
      }
      setInfo((urlParamss.type === 'SMS' ? ('+' + cc) : '') + urlParamss.info);
      if (urlParamss?.otpType) {
        setGenericType('hardcode');
        setResendCode(false);
        setCounter(0);
      }
    }
  }, []);

  return (
    <Layout>
      <div className="w-full items-center h-full justify-center flex-col flex">
        <Header />
        <form
          onSubmit={formik.handleSubmit}
          className="flex justify-center items-center pt-10 md:pt-5 flex-col px-5 lg:w-3/5 md:w-full sm:w-3/5"
        >
          <OTPInput
            formik={formik}
            isInputNum={true}
            numInputs={6}
            name="code"
            separator=""
            hasErrored={false}
            shouldAutoFocus={true}
            inputLabelHeight="auto"
            errorMessage="Wrong verification code."
            labelText={`We have sent a 6 digit verification code to ${info}`}
            containerStyle={{ justifyContent: 'space-between', width: '100%' }}
            bottomText={
              resendCode ? (
                <p className="text-base text-gray-800 font-light pt-2 leading-5">
                  Please wait{' '}
                  <span className={classNames(inputTheme.error, 'text-base')}>
                    {counter} seconds
                  </span>{' '}
                  to resend.
                </p>
              ) : (
                <>
                  <p className="text-base text-gray-800 font-normal pt-2 leading-5">
                    Did not received the code?{' '}
                    <span
                      className="text-theme-primary cursor-pointer"
                      onClick={onResend}
                    >
                      Resend
                    </span>
                  </p>
                  {
                    urlParams?.type === 'EMAIL' ?
                      <p className="text-sm text-gray-800 font-normal pt-2 leading-5">
                        Do check your spam folder if it doesn't arrive in your inbox or{' '}
                        <a
                          className="text-theme-primary cursor-pointer leading-6"
                          href="#"
                        >
                          contact us for help
                        </a>
                      </p>
                      : null
                  }
                </>
              )
            }
          />
          <div className="py-3 w-full ">
            <Button
              type="submit"
              label="Verify >"
              primary={true}
              theme={buttonTheme.primary}
              isLoading={loading || otpApi.loading || recaptchaLoading}
              loader={<Loader height={15} width={15} />}
              disabled={loading || otpApi.loading || recaptchaLoading}
              width="100%"
              height="41px"
            />
          </div>
          <div className="pt-2 text-center font-light text-theme-brown">
            <p>
              By continuing above, you agree to our{' '}
              <a
                className="text-theme-primary cursor-pointer leading-6"
                href={'https://www.doctoroncall.com.my/termsOfService'}
              >
                Terms & Conditions
              </a>
            </p>
          </div>
          <br />
          {
            formik.errors?.generalError ? <ErrorDisplayInline error={formik.errors.generalError} /> : null
          }
        </form>
      </div>
    </Layout>
  );
}

export default CorporateVerify;
