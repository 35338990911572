import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const ContactSupportButton = ({ className, iconWidth }) => {
  const handleSupport = () => {
    // eslint-disable-next-line no-undef
    Gleap?.open();
  };
  return (
    <>
      {/* <Helmet>
        <script>
          {`!function(Gleap,t,i){if(!(Gleap=window.Gleap=window.Gleap||[]).invoked){for(window.GleapActions=[],Gleap.invoked=!0,Gleap.methods=["identify","setEnvironment","setTags","attachCustomData","setCustomData","removeCustomData","clearCustomData","registerCustomAction","trackEvent","log","preFillForm","showSurvey","sendSilentCrashReport","startFeedbackFlow","startBot","setAppBuildNumber","setAppVersionCode","setApiUrl","setFrameUrl","isOpened","open","close","on","setLanguage","setOfflineMode","initialize","disableConsoleLogOverwrite","logEvent","hide","enableShortcuts","showFeedbackButton","destroy","getIdentity","isUserIdentified","clearIdentity","openConversations","openConversation","openHelpCenterCollection","openHelpCenterArticle","openHelpCenter","searchHelpCenter","openNewsArticle","openNews","openFeatureRequests","isLiveMode"],Gleap.f=function(e){return function(){var t=Array.prototype.slice.call(arguments);window.GleapActions.push({e:e,a:t})}},t=0;t<Gleap.methods.length;t++)Gleap[i=Gleap.methods[t]]=Gleap.f(i);Gleap.load=function(){var t=document.getElementsByTagName("head")[0],i=document.createElement("script");i.type="text/javascript",i.async=!0,i.src="https://sdk.gleap.io/latest/index.js",t.appendChild(i)},Gleap.load(),
    Gleap.initialize("FdyagMq88UxLIcCBaIKLgL2UEk2MNP3R")
}}();`}
        </script>
      </Helmet> */}

      <Helmet>
        {/* Gleap script */}
        {/* <!-- Google Tag Manager --> */}
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KRTLRJ8');`}</script>
        {/* <!-- End Google Tag Manager --> */}
      </Helmet>
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <div className={className} role="button" onClick={handleSupport}>
        <img
          src="/assets/icons/support-headphone-icon-black.svg"
          alt="need help"
          width={iconWidth}
        />
        <span className="text-theme-font text-sm font-normal pl-2 hidden sm:block">
          Contact support
        </span>
      </div>
    </>
  );
};

ContactSupportButton.propTypes = {
  className: PropTypes.string,
  iconWidth: PropTypes.number,
};

ContactSupportButton.defaultProps = {
  className: '',
  iconWidth: 16,
};

export default ContactSupportButton;
