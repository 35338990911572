import * as _ from 'lodash';

export default class URL {
  params = {};

  constructor(url, urlParams) {
    this.url = url;
    const params = new URLSearchParams(urlParams || window.location.search);
    this.params = Object.fromEntries(params);
  }

  appendParams(newParams) {
    this.params = {
      ...this.params,
      ...newParams,
    };
    return this;
  }

  removeParams(array) {
    (array || []).forEach((key) => {
      delete this.params[key];
    });
    return this;
  }

  replaceParams(params) {
    this.params = params;
    return this;
  }

  getParams() {
    return this.params;
  }

  getUrl() {
    const params = new URLSearchParams(
      _.omitBy(this.params, _.isNil)
    ).toString();
    const url = `${this.url}?${params}`;

    return url;
  }
}
