import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import Button, { buttonTheme } from '../Button/Button';
import Loader from '../Loader/Loader';

export default function VerificationMethodModal(props) {
  const { onChange, toggleModal, height, width, title } = props;
  return (
    <>
      <Modal
        toggleModal={toggleModal}
        height={height}
        width={width}
        title={title}
      >
        <main className="flex justify-center items-center pt-10 flex-col">
          <div className="py-3 w-full">
            <Button
              type="button"
              onClick={() => onChange('WHATSAPP')}
              label="WhatsApp"
              primary={false}
              theme={buttonTheme.secondary}
              loader={<Loader height={15} width={15} />}
              width="100%"
              height="41px"
              icon="./assets/icons/whatsapp-icon.svg"
              iconPosition="center"
            />
          </div>
          <div className="py-3 w-full ">
            <Button
              onClick={() => onChange('SMS')}
              type="button"
              label="Send SMS"
              primary={false}
              theme={buttonTheme.secondary}
              loader={<Loader height={15} width={15} />}
              width="100%"
              height="41px"
              icon="./assets/icons/sms-icon.svg"
              iconPosition="center"
            />
          </div>
          <div className="py-3 w-full ">
            <Button
              onClick={() => onChange('CALL')}
              type="button"
              label="Make a phone call"
              primary={false}
              loader={<Loader height={15} width={15} />}
              theme={buttonTheme.secondary}
              width="100%"
              height="41px"
              icon="./assets/icons/phone-icon.svg"
              iconPosition="center"
            />
          </div>
        </main>
      </Modal>
    </>
  );
}

VerificationMethodModal.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

VerificationMethodModal.defaultProps = {
  width: '350px',
  height: 'auto',
  title: 'Verification Method',
};
