import * as configUrls from './configUrls';

const SESSION_KEY = '@DoC:key';
const REDIRECT_URI = '@DOC:redirectUri';
const GOOGLE_BUTTON_PAGE = '@DOC:googleButtonPage';

const storeSession = (user) =>
  localStorage.setItem(SESSION_KEY, JSON.stringify(user));

const getSession = () => JSON.parse(localStorage.getItem(SESSION_KEY));

const resetSession = () => localStorage.removeItem(SESSION_KEY);

const storeRedirectUri = (redirectUri) =>
  localStorage.setItem(
    REDIRECT_URI,
    redirectUri || configUrls.REACT_APP_DEFAULT_URL
  );
const getRedirectUri = () => localStorage.getItem(REDIRECT_URI);
const resetRedirectUri = () => localStorage.removeItem(REDIRECT_URI);
const resetUserDetails = () => localStorage.removeItem('userDetails');

const setGoogleBtnUri = (btnUri) =>
  localStorage.setItem(GOOGLE_BUTTON_PAGE, btnUri);
const getGoogleBtnUri = () => localStorage.getItem(GOOGLE_BUTTON_PAGE);
const resetGoogleBtnUri = () => localStorage.removeItem(GOOGLE_BUTTON_PAGE);

export {
  storeSession,
  getSession,
  resetSession,
  storeRedirectUri,
  getRedirectUri,
  resetRedirectUri,
  resetUserDetails,
  setGoogleBtnUri,
  getGoogleBtnUri,
  resetGoogleBtnUri,
};
