import React from 'react';

function Header() {
  return (
    <div className="w-full flex flex-col items-center">
      <div className=" text-center flex-col flex">
        <img
          src="./assets/img/doctoroncall_login.svg"
          alt="doctoroncall login"
          className="h-28 md:block"
        />
        <h1 className="text-theme-gray font-normal text-base leading-7 md:block hidden">
          Get Special Offers & Free Health Tips!
        </h1>
      </div>
    </div>
  );
}

export default Header;
