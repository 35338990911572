import React from 'react';
import PropTypes from 'prop-types';

const ErrorDisplayInline = ({ error }) => (
  <div className="py-3 w-full bg-red-500 p-2 rounded-md box-border">
    <p className="font-normal text-sm text-white">{error}</p>
  </div>
);

export default ErrorDisplayInline;
ErrorDisplayInline.propTypes = {
  error: PropTypes.string,
};
ErrorDisplayInline.defaultProps = {
  error: '',
};
