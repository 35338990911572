import { load } from 'recaptcha-v3-enterprise';
import * as configUrls from './configUrls';

const googleRecaptchaFunc = async () => {
  const recaptcha = await load(configUrls.REACT_APP_RECAPTCHA_SITE_KEY);
  const token = await recaptcha.execute('submit');
  return token;
};

export default googleRecaptchaFunc;
