import React from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import routes from '../../config/routes';
// import Home from '../../views/Home';
// import LoginEmail from '../../views/LoginEmail';
// import Register from '../../views/Register';
import CallBackGoogle from '../../views/callback/Google';
import RouteParameter from '../../components/RouteParameter';
import LoginPhone from '../../views/LoginPhone';
import Auth from '../Auth';
import Logout from '../../views/Logout';
import LoginWithSMS from '../../views/LoginPhone/LoginWithSMS';
import LoginWithEmail from '../../views/LoginEmail/LoginWithEmail';
import ForgetPassword from '../../views/ForgotPassword/ForgetPassword';
import CreateAccount from '../../views/Register/CreateAccount';
import LoginVerification from '../../views/LoginVerification/LoginVerification';
import LoginVerificationOptions from '../../views/LoginVerification/LoginVerificationOptions';
import VerifyEmail from '../../views/VerifyEmail/VerifyEmail';
import ResetPassword from '../../views/ChangePassword/ResetPassword';
import CorporateLogin from '../../views/CorporateActivation/CorporateLogin';
import CorporateVerify from '../../views/CorporateActivation/CorporateVerify';
import CorporatePasswordSetup from '../../views/CorporateActivation/CorporatePasswordSetup';
import CompleteProfile from '../../views/CompleteProfile';

const fallback = <Navigate to={routes.home} />;
const routeComponents = [
  {
    path: routes.home,
    element: LoginWithSMS,
    params: [],
    fallback,
  },
  {
    path: routes.logout,
    element: Logout,
    params: [],
    fallback,
  },
  {
    path: routes.loginEmail,
    element: LoginWithEmail,
    params: [],
    fallback,
  },
  {
    path: routes.register,
    element: CreateAccount,
    params: [],
    fallback,
  },
  {
    path: routes.callbackGoogle,
    element: CallBackGoogle,
    params: [],
    fallback,
  },
  {
    path: routes.loginPhone,
    element: LoginPhone,
    params: ['sessionId', 'phone'],
    fallback,
  },
  {
    path: routes.loginVerificationMethod,
    element: LoginVerificationOptions,
    params: ['phone', 'country'],
    fallback,
  },
  {
    path: routes.forgotPassword,
    element: ForgetPassword,
    params: [''],
    fallback,
  },
  {
    path: routes.forgotPasswordVerify,
    element: VerifyEmail,
    params: ['sessionId'],
    fallback,
  },
  {
    path: routes.resetPassword,
    element: ResetPassword,
    params: [],
    fallback,
  },
  {
    path: routes.loginPhoneVerify,
    element: LoginVerification,
    params: ['sessionId', 'phone', 'country', 'type'],
    fallback,
  },
  {
    path: routes.corporateLogin,
    element: CorporateLogin,
    params: [],
    fallback,
  },
  {
    path: routes.corporateVerify,
    element: CorporateVerify,
    params: [],
    fallback,
  },
  {
    path: routes.corporatePasswordSetup,
    element: CorporatePasswordSetup,
    params: [],
    fallback,
  },
  {
    path: routes.completeProfile,
    element: CompleteProfile,
    params: [],
    fallback,
  },
];

const Routing = () => (
  <Auth>
    <Routes>
      {routeComponents.map(
        ({ path, element: Element, fallback: fallbackComponent, params }) => (
          <Route
            key={path}
            path={path}
            element={
              <RouteParameter params={params} fallback={fallbackComponent}>
                <Element />
              </RouteParameter>
            }
          />
        )
      )}
    </Routes>
  </Auth>
);

export default Routing;
