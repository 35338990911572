import React, { useEffect, useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as apiService from '@doctoroncallcom/api';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import * as configUrls from '../../utils/configUrls';
import googleRecaptchaFunc from '../../utils/recaptcha';
import RegAlertModal from '../../components/AlertModal/AlertModal';
import URL from '../../utils/url';
import CorporateVerifyHeader from '../../components/Layout/CorporateVerifyHeader';
import Layout from '../../components/Layout/Layout';
import Button, { buttonTheme } from '../../components/Button/Button';
import PhoneNumberInput from '../../components/PhoneInput/PhoneInput';
import useApiCall from '../../hooks/useApiCall';
import Loader from '../../components/Loader/Loader';
import routes from '../../config/routes';
import InputLabel, {
  inputLabelTheme,
} from '../../components/InputLabel/InputLabel';
import Input, { inputTheme } from '../../components/Input/Input';
import ErrorResponse from '../../components/ErrorResponse/ErrorResponse';
import ErrorDisplayInline from '../../components/ErrorDisplayInline/ErrorDIsplayInline';
import decodeToken from '../../components/DecodeToken/DecodeToken';

function CorporateLogin() {
  const navigate = useNavigate();
  const [tokenWithPhone, setTokenUserWithPhone] = useState(false);
  const [params, setParams] = useState(null);
  const [paramsGS, setParamsGS] = useState(null);
  const [paramsGM, setParamsGM] = useState(null);
  const [paramsVPN, setParamsVPN] = useState(null);
  const [checkActivation, setCheckActivation] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState('');
  const [recaptchaLoading, setRecaptchaLoading] = useState(false);
  const { loading, data, error, success } = useApiCall(
    params,
    apiService.otp.code
  );
  const checkEmailOtpApi = useApiCall(paramsGS, apiService.user.checkEmail);
  const checkPhoneOtpApi = useApiCall(paramsGM, apiService.user.loginPhone);
  const checkVPNApi = useApiCall(
    paramsVPN,
    apiService.user.validatePhoneNumber
  );
  const checkActivationStatus = useApiCall(
    checkActivation,
    apiService.user.corporateActivate
  );
  const [showRegModal, setShowRegModal] = useState(false);
  const [scenerioType, setScenerioType] = useState('token'); // token,  generic-simple, generic-mobile
  const [tokenData, setTokenData] = useState(null);
  // const [disableActivate, setDisableActivate] = useState(true);

  const validate = (values) => {
    const errors = {};
    if (
      (scenerioType === 'token' || scenerioType === 'generic-mobile') &&
      !values.phoneNumber
    ) {
      errors.phoneNumber = 'Phone number is required.';
    }
    if (scenerioType === 'generic-simple' && !values.email) {
      errors.phoneNumber = 'Email is required.';
    }
    return errors;
  };

  const validCountryCode = (values) => {
    /* eslint-disable */
    let cc = values.countryCode;
    if (!cc.includes('+')) {
      return ('+' + cc);
    } else {
      return cc;
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      phoneNumber: '',
      country: 'MY',
      countryCode: '',
      code: '',
      generalError: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email format.'),
      phoneNumber: Yup.string(),
    }),
    validate,
    onSubmit: (values) => {
      setRecaptchaLoading(true);
      googleRecaptchaFunc()
        .then((token) => {
          setRecaptchaLoading(false);
          const cc = validCountryCode(values);
          /* eslint-disable */
          if (scenerioType === 'token') {
            const objData = {
              countryCode: cc,
              phoneNumber: values.phoneNumber.replace(cc, ''),
              type: 'SMS',
              recaptchaToken: token,
              ...(values.email ? {email: values.email} : {}),
            };
            if (tokenWithPhone) {
              setParams(objData);
            } else {
              setParamsVPN(objData);
            }
          }
          if (scenerioType === 'generic-simple') {
            setParamsGS({
              email: values.email.toLowerCase(),
              recaptchaToken: token,
            });
          }
          if (scenerioType === 'generic-mobile') {
            setParamsGM({
              countryCode: cc,
              phoneNumber: values.phoneNumber.replace(cc, ''),
              type: 'SMS',
              recaptchaToken: token,
            });
          }
          if (scenerioType === 'generic-hardcode') {
            setParamsVPN({
              countryCode: cc,
              phoneNumber: values.phoneNumber.replace(cc, ''),
              type: 'SMS',
              recaptchaToken: token,
              ...(values.email ? {email: values.email} : {}),
            });
          }
        })
        .catch((errToken) => {
          setRecaptchaLoading(false);
          setRecaptchaError(errToken);
        });
    },
  });

  useEffect(() => {
    if (checkActivationStatus.success) {
      // the next lines are for future use. Right now we have to redirect to .com.my in all the cases
      // window.location.href =
      //   checkActivationStatus?.data?.data?.callbackUrls?.redirect ||
      //   `${configUrls.REACT_APP_DEFAULT_URL}/corporate`;
      window.location.href =
        checkActivationStatus?.data?.data?.callbackUrls?.redirect ||
        `https://www.doctoroncall.com.my/corporate`;
    }
  }, [checkActivationStatus.success]);

  useEffect(() => {
    if (checkActivationStatus.error) {
      // console.log(checkActivationStatus.error);
    }
  }, [checkActivationStatus.error]);

  useEffect(() => {
    if (success || checkVPNApi.success) {
      const cc = validCountryCode(formik?.values);
      const url = new URL(routes.corporateVerify)
        .appendParams({
          sessionId: data?.data?.sessionId || checkVPNApi.data?.data?.sessionId,
          st: 'token',
          type: 'SMS',
          email: formik.values?.email.toLowerCase(),
          phoneNumber: formik.values?.phoneNumber.replace(cc, ''),
          country: formik.values?.country,
          /* eslint-disable */
          countryCode: cc,
          verificationStatus:
            scenerioType === 'generic-hardcode'
              ? 'pending'
              : tokenData?.user?.verificationStatus,
          emailVerificationStatus:
            scenerioType === 'generic-hardcode'
              ? 'verified'
              : tokenData?.user?.emailVerificationStatus,
        })
        .getUrl();
      navigate(url);
    }
  }, [success, checkVPNApi.success]);

  useEffect(() => {
    if (checkEmailOtpApi.success) {
      // {"sessionId":"113500-justd","type":"EMAIL","info":"shaheryar93@live.com","otpType":"hardcode"}
      if (checkEmailOtpApi?.data?.data?.otpType === 'hardcode') {
        const url = new URL(routes.corporateVerify)
          .appendParams({
            sessionId: checkEmailOtpApi.data?.data?.sessionId,
            st: scenerioType,
            type: checkEmailOtpApi.data.data?.type,
            info: checkEmailOtpApi.data.data?.info,
            otpType: checkEmailOtpApi.data.data?.otpType,
          })
          .getUrl();
        navigate(url);
      } else {
        const url = new URL(routes.corporateVerify)
          .appendParams({
            sessionId: checkEmailOtpApi.data.data?.sessionId,
            st: scenerioType,
            type: checkEmailOtpApi.data.data?.type,
            ...(checkEmailOtpApi.data.data?.info ? {info: checkEmailOtpApi.data.data?.info} : {info: formik.values?.email.toLowerCase() }),
            ...(checkEmailOtpApi.data.data?.countryCode ? {countryCode: checkEmailOtpApi.data.data?.countryCode} : {}),
          })
          .getUrl();
        navigate(url);
      }
    }
  }, [checkEmailOtpApi.success]);

  useEffect(() => {
    if (checkPhoneOtpApi.success) {
      const cc = validCountryCode(formik?.values);
      const url = new URL(routes.corporateVerify)
        .appendParams({
          sessionId: checkPhoneOtpApi.data.data?.sessionId,
          st: scenerioType,
          type: 'SMS',
          info: formik.values?.phoneNumber.replace(cc, ''),
          countryCode: formik.values?.countryCode,
        })
        .getUrl();
      navigate(url);
    }
  }, [checkPhoneOtpApi.success]);

  useEffect(() => {
    const err = error || checkEmailOtpApi.error || checkPhoneOtpApi.error || checkVPNApi.error || recaptchaError;
    if (err) {
      const errorResponse = ErrorResponse(err);
      if (errorResponse === 'not-registered') {
        setShowRegModal(true);
      } else {
        formik.setErrors({
          generalError: errorResponse,
        });
      }
    }
  }, [error, checkEmailOtpApi.error, checkPhoneOtpApi.error, checkVPNApi.error, recaptchaError]);

  const deCodeToken = (token) => {
    const tokenData = decodeToken(token);
    if (tokenData) {
      let paramsActivate = {};
      setTokenData(tokenData);
      if (tokenData?.user?.email) {
        formik.values.email = tokenData?.user?.email.toLowerCase();
        paramsActivate.email = tokenData?.user?.email.toLowerCase();
      }
      if (tokenData?.user.phoneNumber) {
        setTokenUserWithPhone(true);
        const plus = tokenData.user?.countryCode.includes('+') ? '' : '+';
        formik.values.phoneNumber = (`${plus}${tokenData?.user?.countryCode}` || '+60') + '' + tokenData?.user?.phoneNumber;
        formik.values.countryCode = `${plus}${tokenData?.user?.countryCode}` || '+60';
        paramsActivate.phoneNumber = tokenData?.user?.phoneNumber;
        paramsActivate.countryCode = `${plus}${tokenData?.user?.countryCode}` || '+60';
      }
      // checking if the user is already verified or not.
      setRecaptchaLoading(true);
      googleRecaptchaFunc()
        .then((token) => {
          setRecaptchaLoading(false);
          paramsActivate.recaptchaToken = token;
          setCheckActivation(paramsActivate);
        })
        .catch((errToken) => {
          setRecaptchaLoading(false);
          setRecaptchaError(errToken);
        });
    }
  }

  useEffect(() => {
    const urlSearchParams = new URL();
    const urlParameters = urlSearchParams.getParams();
    if ('activationLink' in urlParameters) {
      setScenerioType('token');
      deCodeToken(urlSearchParams.getParams()?.activationLink);
    } else if ('type' in urlParameters) {
      setScenerioType('generic-hardcode');
      if (urlParameters?.email) {
        formik.values.email = urlParameters?.email.toLowerCase();
      }
    } else if ('activation' in urlParameters) {
      setScenerioType('generic-mobile');
    } else {
      setScenerioType('generic-simple');
    }
  }, []);

  return (
    <Layout>
      <div className="w-full items-center h-full justify-center flex-col flex">
        <CorporateVerifyHeader scenerioType={scenerioType} />
        <main className="flex justify-center items-center pt-10 md:pt-5 flex-col  px-5 lg:w-7/10">
          <form onSubmit={formik.handleSubmit} className="w-full">
            {
              scenerioType === 'generic-simple' ||
              scenerioType === 'generic-hardcode' ||
              scenerioType === 'token' ?
              <div className="mb-4 w-full">
                <InputLabel
                  label="Email"
                  theme={inputLabelTheme.default}
                  height="20px"
                />
                <span className="inline-block w-4 h-4 ml-2 pt-1">
                  {
                    tokenData?.user?.emailVerificationStatus === "verified" || new URL().getParams()?.type === 'hardcode' ?
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" viewBox="0 0 50 50" style={{"fill":"#16a34a"}}><path d="M 25 2 C 12.317 2 2 12.317 2 25 C 2 37.683 12.317 48 25 48 C 37.683 48 48 37.683 48 25 C 48 20.44 46.660281 16.189328 44.363281 12.611328 L 42.994141 14.228516 C 44.889141 17.382516 46 21.06 46 25 C 46 36.579 36.579 46 25 46 C 13.421 46 4 36.579 4 25 C 4 13.421 13.421 4 25 4 C 30.443 4 35.393906 6.0997656 39.128906 9.5097656 L 40.4375 7.9648438 C 36.3525 4.2598437 30.935 2 25 2 z M 43.236328 7.7539062 L 23.914062 30.554688 L 15.78125 22.96875 L 14.417969 24.431641 L 24.083984 33.447266 L 44.763672 9.046875 L 43.236328 7.7539062 z"></path></svg>
                    :
                    <svg style={{"fill":"#eab308", "width":"100%"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z"/></svg>
                  }
                </span>
                <Input
                  // theme={inputTheme.plain}
                  type="text"
                  disabled={tokenData?.user?.email !== undefined || scenerioType === 'generic-hardcode'}
                  readOnly={false}
                  formik={formik}
                  name="email"
                  id="email"
                  height="41px"
                  width="100%"
                  placeholder="yourname@corporate-email.com"
                />
              </div>
              : null
            }
            {
              scenerioType === 'generic-mobile' ||
              scenerioType === 'generic-hardcode' ||
              scenerioType === 'token' ?
              <div className="mb-4 w-full">
                <InputLabel
                  label="Mobile Number"
                  theme={inputLabelTheme.default}
                  height="20px"
                />
                <span className="inline-block w-4 h-4 ml-2 pt-1">
                  {
                    tokenData?.user?.verificationStatus === "verified" ?
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" viewBox="0 0 50 50" style={{"fill":"#16a34a"}}><path d="M 25 2 C 12.317 2 2 12.317 2 25 C 2 37.683 12.317 48 25 48 C 37.683 48 48 37.683 48 25 C 48 20.44 46.660281 16.189328 44.363281 12.611328 L 42.994141 14.228516 C 44.889141 17.382516 46 21.06 46 25 C 46 36.579 36.579 46 25 46 C 13.421 46 4 36.579 4 25 C 4 13.421 13.421 4 25 4 C 30.443 4 35.393906 6.0997656 39.128906 9.5097656 L 40.4375 7.9648438 C 36.3525 4.2598437 30.935 2 25 2 z M 43.236328 7.7539062 L 23.914062 30.554688 L 15.78125 22.96875 L 14.417969 24.431641 L 24.083984 33.447266 L 44.763672 9.046875 L 43.236328 7.7539062 z"></path></svg>
                    :
                    <svg style={{"fill":"#eab308", "width":"100%"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z"/></svg>
                  }
                </span>
                <PhoneNumberInput
                  formik={formik}
                  width="100%"
                  height="41px"
                  name="phoneNumber"
                  countryInputName="country"
                  countryCode="countryCode"
                  errorText="Please provide a valid phone number*"
                  disabled={tokenData?.user?.phoneNumber !== undefined}
                  inputLabelHeight="30px"
                />
              </div>
              : null
            }
            <div className="py-3 w-full ">
              <Button
                type="submit"
                label="Verify >"
                primary={true}
                theme={buttonTheme.primary}
                isLoading={loading || checkEmailOtpApi?.loading || checkPhoneOtpApi?.loading || recaptchaLoading}
                loader={<Loader height={15} width={15} color="white" />}
                disabled={loading || checkEmailOtpApi?.loading || checkPhoneOtpApi?.loading || recaptchaLoading}
                width="100%"
                height="41px"
              />
            </div>
            {
              formik.errors?.generalError ? <ErrorDisplayInline error={formik.errors.generalError} /> : null
            }
          </form>
          <div className="pt-4 font-light text-gray-600 text-center">
            <p>
              By Clicking on Sign In, you agree to our{' '}
              <a
                className="text-theme-primary cursor-pointer leading-6"
                href="https://www.doctoroncall.com.my/termsOfService"
              >
                Terms & Conditions
              </a>
            </p>
          </div>
        </main>
      </div>
      {showRegModal && (
        <RegAlertModal
          onCancel={() => setShowRegModal(false)}
          onConfirm={() => {
            navigate(new URL(routes.register).getUrl());
          }}
          width="328px"
          height="196px"
          title=""
        />
      )}
    </Layout>
  );
}

export default CorporateLogin;
