import URL from '../../utils/url';
import { storeRedirectUri } from '../../utils/session';

const setRedirectUri = () => {
  const urlSearchParams = new URL();
  const redirectSearchValue = urlSearchParams.getParams()?.redirectUri;
  let hashValue = '';
  if (redirectSearchValue) {
    /* eslint-disable */
    hashValue = window.location.href.split(redirectSearchValue)[1];
  }
  let redirectUri = redirectSearchValue + hashValue;
  if (redirectUri) {
    if (
      redirectUri.includes('doconcall.com') ||
      redirectUri.includes('doctoroncall.com') ||
      redirectUri.includes('localhost')
    ) {
      if (redirectUri.includes('undefined')) {
        redirectUri = redirectUri.replace('undefined', '');
      }
      storeRedirectUri(redirectUri);
    }
  }
};

export default setRedirectUri;
