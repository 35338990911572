import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Loader(props) {
  const { height, width, hasOverlay, color } = props;
  const loader = Object.freeze({
    loader_con:
      'fixed top-0 left-0 h-screen w-full bg-gray-200 flex items-center justify-center z-50',
  });
  return (
    <div className={classNames({ [loader.loader_con]: hasOverlay })}>
      <div
        style={{
          width,
          height,
          border: `${parseInt(height, 10) / 5}px solid ${
            color === 'white' ? '#1191EA' : '#cccccc'
          }`,
          borderTop: `${parseInt(width, 10) / 5}px solid ${color}`,
        }}
        className="animate-spin rounded-full"
      />
    </div>
  );
}

Loader.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasOverlay: PropTypes.bool,
  color: PropTypes.string,
};
Loader.defaultProps = {
  height: '50px',
  width: '50px',
  hasOverlay: false,
  color: '#3498db',
};
