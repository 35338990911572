import { useState, useEffect } from 'react';
import api from '../api';

function trimPayload(paramObject) {
  if (Array.isArray(paramObject)) {
    return paramObject;
  }

  const params = { ...paramObject };
  Object.keys(params).forEach((key) => {
    if (
      params[key] === undefined ||
      params[key] === null ||
      params[key] === ''
    ) {
      delete params[key];
    }
  });

  return params;
}
const useApiCall = (params, func) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (params) {
      setLoading(true);
      setSuccess(null);
      setError(null);
      func(api, trimPayload(params))
        .then((response) => {
          setData(response);
          setLoading(false);
          setSuccess(true);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    }
  }, [JSON.stringify(params)]);

  return { loading, data, error, success };
};

export default useApiCall;
