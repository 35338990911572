import React from 'react';
import PropTypes from 'prop-types';

function ForgetPasswordHeader(props) {
  const { text } = props;
  return (
    <div>
      <h2 className=" text-theme-gray text-lg	leading-relaxed sm:font-normal font-bold pb-3">
        {text}
      </h2>
      <hr className="sm:block hidden" />
    </div>
  );
}

export default ForgetPasswordHeader;

ForgetPasswordHeader.propTypes = {
  text: PropTypes.string,
};

ForgetPasswordHeader.defaultProps = {
  text: '',
};
