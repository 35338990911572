import * as session from './session';

const checkUserRole = (user) => {
  const redirectUri = session.getRedirectUri() || '';
  if (!user?.role || user?.role === 'PATIENT' || user?.role === 'NULL') {
    if (
      redirectUri &&
      (redirectUri.includes('admin.doctor') ||
        redirectUri.includes('admin.dev.doctor') ||
        redirectUri.includes('doctor.dev.doctor') ||
        redirectUri.includes('doctor.doctoroncall'))
    ) {
      return false;
    }
    return true;
  }
  if (user?.role && user?.role === 'DOCTOR') {
    if (
      redirectUri &&
      (redirectUri.includes('admin.doctoroncall') ||
        redirectUri.includes('admin.dev.doctoroncall'))
    ) {
      return false;
    }
    return true;
  }
  if (
    user?.role &&
    (user?.role === 'READ_ADMIN' || user?.role === 'WRITE_ADMIN')
  ) {
    if (
      redirectUri &&
      (redirectUri.includes('doctor.dev.doctoroncall') ||
        redirectUri.includes('doctor.doctoroncall'))
    ) {
      return false;
    }
    return true;
  }
  return true;
};

export default checkUserRole;
