const ErrorResponse = (error) => {
  if (error?.code === 401) {
    return 'You have entered invalid credentials/code. Please try again.';
  }
  if (error?.code === 403) {
    return 'There seems to be some issue with our system. Please Contact Support or try again later.';
  }
  if (error?.code === 404) {
    return 'not-registered';
  }
  if (error?.code === 409) {
    return 'This Email/Phone number already exists in our system. Please try again with different Email/Phone number values.';
  }
  if (error?.code === 422) {
    return 'Invalid recaptcha token. Please refresh the page and try again.';
  }
  if (error?.code === 429) {
    return 'You can not send more then 1 request per minute. Please wait for 60 seconds before sending another request.';
  }
  if (error?.code === 500 || error?.code === 501) {
    if (
      error.code === 501 &&
      error['0'] === 'I' &&
      error['1'] === 'n' &&
      error['2'] === 'v' &&
      error['3'] === 'a' &&
      error['4'] === 'l'
    ) {
      return 'Invalid Mobile Number';
    }
    return 'Oops! Something went wrong with the server. Please try again later or refresh the page.';
  }
  return '';
};

export default ErrorResponse;
