import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const inputTheme = Object.freeze({
  plain:
    'border-cloud-200 rounded-md border border-solid font-light outline-none px-5',
  error: 'text-red-400 text-xs font-light	leading-5',
});

function Input(props) {
  const {
    name,
    id,
    theme,
    height,
    width,
    placeholder,
    formik,
    label,
    type,
    disabled,
    readOnly,
    onFocus,
    button,
  } = props;
  const { errors, touched } = formik;

  const [passType, setpassType] = useState(type);

  function showHidePassword() {
    const show = document.getElementById(id);
    if (show.type === 'password') {
      show.type = 'text';
      setpassType('text');
    } else {
      show.type = 'password';
      setpassType('password');
    }
  }
  const { value, onChange, onBlur } = formik.getFieldProps(name);

  return (
    <div>
      {label}
      <div
        className={classNames(
          disabled ? 'cursor-not-allowed' : '',
          'flex items-center justify-between relative',
          theme
        )}
        style={
          disabled
            ? {
                height,
                width,
                backgroundColor: 'rgba(204, 204, 204, 0.25)',
              }
            : { height, width }
        }
      >
        <div className="w-full">
          <input
            id={id}
            name={name}
            className="outline-none w-full text-theme-gray placeholder:text-xs"
            placeholder={placeholder}
            {...props}
            disabled={disabled}
            readOnly={readOnly}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChange}
            value={value}
            style={
              disabled
                ? {
                    cursor: 'not-allowed',
                  }
                : {}
            }
          />
        </div>
        {type === 'password' && (
          <div>
            <img
              src={
                passType === 'password'
                  ? './assets/icons/password-hide-icon.svg'
                  : './assets/icons/password-show-icon.svg'
              }
              alt="passowrd-icon"
              className="cursor-pointer"
              onClick={showHidePassword}
            />
          </div>
        )}
        {button}
      </div>
      {errors[name] && touched[name] && (
        <div className={inputTheme.error}>{errors[name]}</div>
      )}
    </div>
  );
}

export default Input;
Input.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.objectOf(PropTypes.any).isRequired,
  theme: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  button: PropTypes.element,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Input.defaultProps = {
  placeholder: '',
  theme: inputTheme.plain,
  label: undefined,
  disabled: false,
  readOnly: false,
  onBlur: undefined,
  onFocus: undefined,
  onChange: undefined,
  button: undefined,
  value: '',
};
