import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { user, otp } from '@doctoroncallcom/api';
import api from '../../api';
import googleRecaptchaFunc from '../../utils/recaptcha';
import Layout from '../../components/Layout/Layout';
import Button, { buttonTheme } from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import CompleteProfileForm from '../../components/CompleteProfileForm';
import Overlay from '../../components/Overlay';
import Modal from '../../components/Modal/Modal';
import ContactSupportButton from '../../components/ContactSupportButton';

// eslint-disable-next-line prefer-const
// let Gleap = null;

const CompleteProfile = () => {
  const [overlayLoading, setOverlayLoading] = useState(false);
  const [resendCode, setResendCode] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);
  const [recaptchaError, setRecaptchaError] = useState('');
  const [type, setType] = useState('SMS');
  const [showOtp, setShowOtp] = useState(false);
  const [showVerificationOption, setShowVerificationOption] = useState(false);
  const [profile, setProfile] = useState({});
  const [getOtpCodeData, setGetOtpCodeData] = useState(null);
  const [getOtpCodeError, setGetOtpCodeError] = useState(null);
  const [updateProfileError, setUpdateProfileError] = useState({
    code: 0,
  });
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlSearchParams = new URLSearchParams(location.search);
  const step = searchParams.get('step')
    ? parseInt(searchParams.get('step'), 10)
    : 1;
  const navigate = useNavigate();
  // const router = useRout
  const date = new Date();

  date.setFullYear(date.getFullYear() - 10);

  const checkIsIc = (value) => {
    if (value.length === 12) {
      return true;
    }
    return false;
  };

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      name: '',
      code: '',
      country: 'MY',
      countryCode: '60',
      icOrPassport: '',
      dob: date,
      gender: '',
      generalError: '',
      sessionId: '',
      address: {
        country: '',
        city: '',
        zipCode: '',
        street: '',
        house: '',
      },
      email: '',
    },
    validationSchema: Yup.object().shape({
      ...(step === 1
        ? {
            icOrPassport: Yup.string().required('Required*'),
            dob: Yup.date().nullable().required('Required*'),
            gender: Yup.string().required('Required*'),
            name: Yup.string().required('Required*'),
          }
        : {}),
      ...(step === 2
        ? {
            phoneNumber: Yup.string().required('Required*'),
            countryCode: Yup.string().required('Required*'),
            address: Yup.object().shape({
              country: Yup.string().required(),
              city: Yup.string().required(),
              zipCode: Yup.string().required(),
              street: Yup.string().required(),
              house: Yup.string(),
            }),
          }
        : {}),
      code: showOtp && Yup.string().required('Required*'),
      sessionId: Yup.string(),
    }),
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values) => {
      if (
        !(values.icOrPassport && values.dob && values.gender && values.name)
      ) {
        navigate(-1);
      } else {
        console.log('submit:::', values);
        setOverlayLoading(true);
        googleRecaptchaFunc()
          // eslint-disable-next-line no-unused-vars
          .then(async (token) => {
            const userData = {
              ...(formik.values.phoneNumber !==
              `${profile.countryCode}${profile.phoneNumber}`
                ? {
                    phoneNumber: values.phoneNumber
                      .replace(values.countryCode, '')
                      .replace('+', ''),
                  }
                : {}),
              countryCode: `+${values.countryCode}`,
              sessionId: values.sessionId,
              code: values.code,
              dob: values.dob.toISOString().split('T')[0],
              gender: values.gender,
              identification: {
                ...(!checkIsIc(values.icOrPassport)
                  ? { passport: values.icOrPassport }
                  : {}),
                ...(checkIsIc(values.icOrPassport)
                  ? { ic: values.icOrPassport }
                  : {}),
              },
              address: values.address,
              email: values.email,
              name: values.name,
            };

            try {
              await user.putProfile(api, userData);
              const redirectUri = searchParams.get('redirectUri');
              window.location = redirectUri;
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(error);
              setUpdateProfileError(error);
            }
            setOverlayLoading(false);
          })
          .catch((errToken) => {
            setOverlayLoading(false);
            setRecaptchaError(errToken);
          });
      }
    },
  });
  // eslint-disable-next-line no-unused-vars
  const onResend = (typeValue) => {
    setOverlayLoading(true);
    googleRecaptchaFunc()
      .then(async (token) => {
        setOverlayLoading(false);
        const otpData = {
          type: typeValue,
          countryCode: `+${formik.values.countryCode}`,
          phoneNumber: formik.values.phoneNumber
            .replace(formik.values.countryCode, '')
            .replace('+', ''),
          recaptchaToken: token,
        };
        try {
          const res = await otp.code(api, otpData);
          setGetOtpCodeData(res.data);
        } catch (error) {
          setGetOtpCodeError(error);
        }
      })
      .catch((errToken) => {
        setOverlayLoading(false);
        setRecaptchaError(errToken);
      });
  };

  const verifyPhoneAndDispatchCode = () => {
    setOverlayLoading(true);
    console.log('111');
    googleRecaptchaFunc()
      .then(async (token) => {
        setOverlayLoading(false);
        const otpData = {
          type,
          countryCode: `+${formik.values.countryCode}`,
          phoneNumber: formik.values.phoneNumber
            .replace(formik.values.countryCode, '')
            .replace('+', ''),
          recaptchaToken: token,
        };
        console.log(`otpData:::${otpData}`);
        try {
          console.log(`setShowOtp:::`);
          setShowOtp(true);
          console.log(`setResendCode:::`);
          setResendCode(true);
          console.log(`setCounter:::`);
          setCounter(60);
          console.log(`res:::`);
          const res = await otp.code(api, otpData);
          console.log(`setGetOtpCodeData:::`);
          setGetOtpCodeData(res.data);
        } catch (error) {
          setGetOtpCodeError(error);
        }
      })
      .catch((errToken) => {
        setOverlayLoading(false);
        setRecaptchaError(errToken);
      });
  };

  const setTypeAndGoBack = (value) => {
    setType(value);
    setShowVerificationOption(false);
    onResend(value);
  };

  const handleContinue = (e) => {
    e.preventDefault();
    searchParams.set('step', 2);
    setSearchParams(searchParams);
  };

  const loadProfile = async () => {
    setOverlayLoading(true);
    try {
      const token = urlSearchParams.get('token');
      api.setAuthorizationToken(token);
      const res = await user.getProfile(api);
      console.log('res', res);
      setProfile(res.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    setOverlayLoading(false);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const timer =
      counter > 0 &&
      showOtp &&
      setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setResendCode(false);
    }
    return () => clearInterval(timer);
  }, [counter, showOtp]);

  useEffect(() => {
    setIsContinueDisabled(true);
    formik.validateForm();
    console.log(formik.errors);
    if (Object.keys(formik.errors).length > 0) {
      setIsContinueDisabled(true);
      return;
    }
    if (
      step === 2 &&
      formik.values.phoneNumber !==
        `${profile.countryCode}${profile.phoneNumber}` &&
      formik.values.code.length !== 6
    ) {
      setIsContinueDisabled(true);
      return;
    }

    setIsContinueDisabled(false);
  }, [formik.values, formik.errors, step]);

  useEffect(() => {
    if (checkIsIc(formik.values.icOrPassport)) {
      const values = { ...formik.values };

      const year = parseInt(values.icOrPassport.slice(0, 2), 10);
      const month = parseInt(values.icOrPassport.slice(2, 4), 10) - 1;
      const day = values.icOrPassport.slice(4, 6);
      const gender =
        parseInt(values.icOrPassport.slice(8, 12), 10) % 2 === 0
          ? 'female'
          : 'male';

      const currentYear = new Date().getFullYear() % 100;

      const centuryThreshold = 50;

      let fullYear;
      if (year <= currentYear + centuryThreshold) {
        fullYear = 2000 + year;
      } else {
        fullYear = 1900 + year;
      }
      const dob = new Date(fullYear, month, day);
      values.dob = dob;
      values.gender = gender;

      formik.setValues(values);
    }
  }, [formik.values.icOrPassport]);

  useEffect(() => {
    if (getOtpCodeError) {
      if (
        getOtpCodeError.code === 429 &&
        getOtpCodeError.message === 'API rate limit exceeded'
      ) {
        alert(
          'You can only get 1 code per minute. Please wait a little before verifying again.'
        );
        formik.setErrors({
          generalError:
            'API rate limit exceeded, you can only get 1 code per minute. Please wait a little before verifying again.',
        });
      }
    }
  }, [getOtpCodeError]);

  useEffect(() => {
    if (updateProfileError) {
      if (
        updateProfileError.code === 401 &&
        updateProfileError.message === 'Request failed with status code 401'
      ) {
        formik.setErrors({
          generalError: 'You have entered an invalid code. Please try again.',
        });
      }
    }
    if (updateProfileError.code === 409) {
      formik.setErrors({
        generalError: updateProfileError.message,
      });
    }
  }, [updateProfileError]);

  useEffect(() => {
    if (getOtpCodeData?.sessionId) {
      formik.setFieldValue('sessionId', getOtpCodeData.sessionId);
      // setShowOtp(true);
      // setResendCode(true);
      // setCounter(60);
    }
  }, [getOtpCodeData]);

  useEffect(() => {
    document.title = 'Complete Profile';
    loadProfile();
  }, []);

  useEffect(() => {
    setIsContinueDisabled(true);
    if (profile) {
      console.log('profile:::', profile);
      if (
        profile.name &&
        profile.gender &&
        profile.dob &&
        (profile.identification?.passport || profile.identification?.ic) &&
        (profile.address?.country ||
          profile.address?.zipCode ||
          profile.address?.city ||
          profile.address?.street ||
          profile.address?.house) &&
        profile.phoneNumber &&
        profile.countryCode
      )
        setIsContinueDisabled(false);
      const initValues = formik.initialValues;

      // if (profile.name) initValues.name = profile.name;
      // else initValues.name = profile.email;
      // if (profile.name) initValues.name = profile.name;
      if (profile.gender) initValues.gender = profile.gender;

      if (profile.identification?.passport)
        initValues.icOrPassport = profile.identification.passport;
      else if (profile.identification?.ic)
        initValues.icOrPassport = profile.identification.ic;

      if (profile.dob) initValues.dob = new Date(profile.dob);

      if (profile.email) initValues.email = profile.email;

      if (profile.address) initValues.address = profile.address;

      if (profile.countryCode)
        initValues.countryCode = profile.countryCode.replaceAll('+', '');
      if (profile.phoneNumber)
        initValues.phoneNumber = `${profile.countryCode}${profile.phoneNumber}`;

      formik.setValues(initValues);

      // move step on load
      // if (
      //   profile.name &&
      //   (profile.identification?.ic || profile.identification?.passport) &&
      //   profile.dob &&
      //   profile.gender
      // ) {
      //   searchParams.set('step', 2);
      //   setSearchParams(searchParams);
      // }
    }
  }, [profile]);

  return (
    <div className="h-screen md:bg-gray-200 xl:px-56 lg:px-26 md:px-14 flex justify-center items-center py-6">
      <div className="bg-white w-full md:rounded-xl h-full">
        {overlayLoading && <Overlay />}

        <div className="relative w-full h-full overflow-y-auto">
          <ContactSupportButton
            className="md:m-4 p-4 absolute right-0 sm:top-0  w-fit flex"
            iconWidth={20}
          />

          <div className="w-full 2xl:w-6/12 md:w-9/12 mx-auto h-full flex flex-col p-4 gap-y-3">
            <div className="w-full h-10 flex justify-center relative">
              <img
                src="./assets/img/doc_logo_img_new.svg"
                alt="DoC Logo"
                className="smooth-transition w-24"
                onClick={() => navigate('/')}
              />
            </div>
            <div className="2xl:px-12 xl:px-0 justify-start w-full sm:flex hidden select-none">
              <div
                className="flex items-center justify-between w-12 cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <img
                  src="./assets/icons/left-arrow.svg"
                  alt="back button"
                  className="p-3"
                />
                Back
              </div>
            </div>
            <div className="my-0 mx-auto w-full ">
              <div className="text-center">
                <h2 className="text-xl  md:pb-3">
                  You’re Almost Done! Please Complete Your Profile.
                </h2>
                <p className="text-sm text-gray-500">
                  We are missing some details.
                </p>
              </div>
            </div>
            <div className="mx-auto w-9/12">
              <div className="px-1 mb-3">
                <p className="text-sm text-gray-500 mb-2">
                  Basic Details: Step {step}/2
                </p>
                <div className="w-full bg-white border rounded-full h-2.5 dark:bg-gray-700">
                  <div
                    className={`bg-blue-secondary h-2.5 rounded-full transition-width transition-slowest ease ${
                      step === 1 ? 'w-5/12' : 'w-9/12'
                    }`}
                  />
                </div>
              </div>
              <CompleteProfileForm
                formik={formik}
                isContinueDisabled={isContinueDisabled}
                onResend={onResend}
                resendCode={resendCode}
                showOtp={showOtp}
                type={type}
                counter={counter}
                setShowVerificationOption={setShowVerificationOption}
                verifyPhoneAndDispatchCode={verifyPhoneAndDispatchCode}
                step={step}
                handleContinue={handleContinue}
                // isIc={checkIsIc(formik.values.icOrPassport)}
              />
              {showVerificationOption && (
                <Modal mobileClass="completeProfilePopup">
                  <div className="flex flex-col py-10 px-8">
                    <h4 className="text-lg font-semibold pb-5 text-center">
                      Select one of the methods below to send verification code
                      to ***********{formik.values.phoneNumber.slice(-2)}
                    </h4>
                    {type !== 'SMS' && (
                      <div className="py-3 w-full ">
                        <Button
                          onClick={() => setTypeAndGoBack('SMS')}
                          type="button"
                          label="Send SMS"
                          primary={false}
                          isLoading={overlayLoading}
                          disabled={overlayLoading}
                          loader={<Loader height={15} width={15} />}
                          theme={buttonTheme.secondary}
                          width="100%"
                          height="41px"
                          icon="/assets/icons/sms-icon.svg"
                          iconPosition="center-left"
                          centerContent={true}
                        />
                      </div>
                    )}
                    {type !== 'WHATSAPP' && (
                      <div className="py-3 w-full">
                        <Button
                          type="button"
                          onClick={() => setTypeAndGoBack('WHATSAPP')}
                          label="WhatsApp"
                          primary={false}
                          isLoading={overlayLoading}
                          disabled={overlayLoading}
                          theme={buttonTheme.secondary}
                          loader={<Loader height={15} width={15} />}
                          width="100%"
                          height="41px"
                          icon="/assets/icons/whatsapp-icon.svg"
                          iconPosition="center-left"
                          centerContent={true}
                        />
                      </div>
                    )}
                    {type !== 'CALL' && (
                      <div className="py-3 w-full ">
                        <Button
                          onClick={() => setTypeAndGoBack('CALL')}
                          type="button"
                          label="Make a phone call"
                          primary={false}
                          isLoading={overlayLoading}
                          disabled={overlayLoading}
                          loader={<Loader height={15} width={15} />}
                          theme={buttonTheme.secondary}
                          width="100%"
                          height="41px"
                          icon="/assets/icons/phone-icon.svg"
                          iconPosition="center-left"
                          centerContent={true}
                        />
                      </div>
                    )}
                  </div>
                </Modal>
              )}
            </div>
          </div>
          {recaptchaError && (
            <div className="mt-4 w-full px-8">
              <div className="text-sm bg-theme-text-error text-white py-2 px-3 rounded-lg font-semibold">
                Recaptcha Error: It seems like the recaptcha is not working at
                the moment. We are looking into this issue. Try to refresh the
                page or try again later.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompleteProfile;
