import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { user } from '@doctoroncallcom/api';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import * as configUrls from '../../utils/configUrls';
import Button, { buttonTheme } from '../../components/Button/Button';
import Input, { inputTheme } from '../../components/Input/Input';
import AlertCard from '../../components/AlertCard';
import InputLabel, {
  inputLabelTheme,
} from '../../components/InputLabel/InputLabel';
import Layout from '../../components/Layout/Layout';
import ForgetPasswordHeader from '../../components/Layout/ForgetPasswordHeader';
// import BackButton from '../../components/Layout/BackButton';
import useApiCall from '../../hooks/useApiCall';
import Loader from '../../components/Loader/Loader';
import URL from '../../utils/url';
import googleRecaptchaFunc from '../../utils/recaptcha';
import ErrorResponse from '../../components/ErrorResponse/ErrorResponse';
import ErrorDisplayInline from '../../components/ErrorDisplayInline/ErrorDIsplayInline';
import { REACT_APP_PASSWORD_REGEX } from '../../utils/configUrls';

function CorporatePasswordSetup() {
  const navigate = useNavigate();
  const [recaptchaError, setRecaptchaError] = useState('');
  const [recaptchaLoading, setRecaptchaLoading] = useState(false);
  const [params, setParams] = useState(null);
  const [urlParams, setUrlParams] = useState(null);
  const { loading, data, error, success } = useApiCall(
    params,
    user.corporateVerify
  );
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      generalError: '',
    },

    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(8, 'At least 8 characters.')
        .required('Required*')
        .matches(
          REACT_APP_PASSWORD_REGEX,
          'Password should contain a special character.'
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Password must match.')
        .required('Required*')
        .matches(
          REACT_APP_PASSWORD_REGEX,
          'Password should contain a special character.'
        ),
    }),
    onSubmit: (values) => {
      setRecaptchaLoading(true);
      googleRecaptchaFunc()
        .then((token) => {
          setRecaptchaLoading(false);
          /* eslint-disable */
          setParams({
            password: values.password,
            code: urlParams?.code.trim(' '),
            sessionId: urlParams?.sessionId,
            recaptchaToken: token,
            verificationStatus: urlParams?.verificationStatus,
            emailVerificationStatus: urlParams?.emailVerificationStatus,
            ...(urlParams?.info ? {phoneNumber: urlParams?.info} : {}),
            ...(urlParams?.email ? {email: urlParams?.email} : {}),
            ...(urlParams?.countryCode ? {countryCode: urlParams?.countryCode} : {}),
          });
        })
        .catch((errToken) => {
          setRecaptchaLoading(false);
          setRecaptchaError(errToken);
        });
    },
  });

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        // navigate('/login-email');
        // the next line is for future use. Right now we have to redirect to .com.my in all the cases
        // window.location.href = data?.data?.callbackUrls?.redirect || configUrls.REACT_APP_DEFAULT_URL + '/corporate';
        window.location.href = data?.data?.callbackUrls?.redirect || 'https://www.doctoroncall.com.my/corporate';
      }, 3000);
    }
  }, [success]);

  useEffect(() => {
    if (error || recaptchaError) {
      const err = error || recaptchaError;
      const errorResponse = ErrorResponse(err);
      if (errorResponse === 'not-registered') {
        formik.setErrors({
          generalError: "We were unable to find this user in our system, so We could not activate it. Please contact support for further assistance."
        })
      } else {
        formik.setErrors({
          generalError: errorResponse,
        });
      }
    }
  }, [error, recaptchaError]);

  useEffect(() => {
    const urlParamss = new URL().getParams();
    setUrlParams(urlParamss);
  }, []);

  return (
    <Layout>
      <div className="w-full items-center h-full flex-col flex">
        {/* <BackButton
          onClick={() => {
            navigate('/change-password');
          }}
        /> */}
        <main className="flex flex-col px-5 sm:pt-24 pt-10 lg:w-3/5 md:w-full sm:w-3/5 w-full">
          <ForgetPasswordHeader text="Reset Password" />
          <form onSubmit={formik.handleSubmit} className="w-full pt-3">
            <div className="pb-2 w-full">
              <Input
                theme={inputTheme.plain}
                label={
                  <InputLabel
                    label="New Password"
                    theme={inputLabelTheme.default}
                    height="30px"
                  />
                }
                type="password"
                disabled={false}
                readOnly={false}
                formik={formik}
                name="password"
                id="password"
                height="41px"
                width="100%"
                placeholder="Enter your new password"
              />
            </div>
            <div className="pb-2 w-full">
              <Input
                theme={inputTheme.plain}
                label={
                  <InputLabel
                    label="Retype New Password"
                    theme={inputLabelTheme.default}
                    height="30px"
                  />
                }
                type="password"
                disabled={false}
                readOnly={false}
                formik={formik}
                name="confirmPassword"
                id="confirmPassword"
                height="41px"
                width="100%"
                placeholder="Re-enter your new password"
              />
            </div>
            <div className="pt-6 w-full ">
              <Button
                type="submit"
                label="Activate"
                primary={true}
                theme={buttonTheme.primary}
                isLoading={loading || recaptchaLoading}
                loader={<Loader height={15} width={15} color="white" />}
                disabled={loading || recaptchaLoading}
                width="100%"
                height="41px"
              />
            </div>
            {success && (
              <div className="pt-6 w-full">
                {/* eslint-disable */}
                <AlertCard height="auto" theme="bg-green-600 " width="100%">
                  <div className="font-normal text-sm text-white">
                    Password updated successfully. You will be redirected to
                    Login Page.
                  </div>
                </AlertCard>
              </div>
            )
            }
            <br />
            {
              formik.errors?.generalError ? <ErrorDisplayInline error={formik.errors.generalError} /> : null
            }
          </form>
        </main>
      </div>
    </Layout>
  );
}

export default CorporatePasswordSetup;
