import React from 'react';
import PropTypes from 'prop-types';

// import PhoneInput from 'react-phone-input-2';
// import libphonenumbers from 'libphonenumbers';

import 'react-phone-number-input/style.css';
import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import 'react-phone-input-2/lib/style.css';
import { inputTheme } from '../Input/Input';
import InputLabel from '../InputLabel/InputLabel';
import './phone.css';

// const phoneUtil = libphonenumbers.PhoneNumberUtil.getInstance();

const classNames = require('classnames');

export default function PhoneNumberInput(props) {
  const {
    width,
    height,
    placeholder,
    //  events
    onFocus,
    onClick,
    onKeyDown,
    //  boolean
    disabled,
    errorText,
    inputLabelHeight,
    hasButton,
    button,
    inputTheme: inpTheme,
    formik,
    name,
    countryInputName,
    countryCode,
  } = props;

  const onChangePhone = (value) => {
    if (value?.length > 4) {
      const parsedValue = parsePhoneNumber(value);
      formik.setFieldValue(name, parsedValue?.number);
      formik.setFieldValue(countryInputName, parsedValue?.country);
      formik.setFieldValue(countryCode, parsedValue?.countryCallingCode);
    }
  };

  const checkPhoneNumberValidation = (number) => {
    try {
      if (isValidPhoneNumber(number)) {
        formik.setFieldError();
      } else {
        formik.setFieldError(name, errorText);
      }
    } catch (error) {
      formik.setFieldError(name, errorText);
    }
  };

  const onBlur = (event) => {
    const num = event.target.value;
    formik.setFieldTouched(name, true, false);
    checkPhoneNumberValidation(num);
  };

  const { value } = formik.getFieldProps(name);
  const { errors, touched } = formik;

  if (hasButton) {
    return (
      <div
        className={classNames(
          'flex items-center justify-between relative',
          inpTheme
        )}
        style={{ height, width }}
      >
        <div
          className={classNames(
            disabled ? 'phone-input-disabled' : '',
            'w-full h-full'
          )}
        >
          <PhoneInput
            placeholder={placeholder}
            value={value}
            onChange={onChangePhone}
            defaultCountry={formik.values.country}
            country={formik.values.country}
            international={true}
            onBlur={onBlur}
            onClick={onClick}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            disabled={disabled}
          />
        </div>
        {button}
      </div>
    );
  }

  return (
    <div
      className={classNames(
        disabled ? 'phone-input-disabled' : '',
        'phone-container'
      )}
      style={{ height, width }}
    >
      <PhoneInput
        placeholder={placeholder}
        value={value}
        onChange={onChangePhone}
        defaultCountry={formik.values.country}
        country={formik.values.country}
        international={true}
        onBlur={onBlur}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        disabled={disabled}
      />
      {errors[name] && touched[name] && (
        <InputLabel
          theme={classNames(inputTheme.error)}
          label={errorText}
          height={inputLabelHeight}
        />
      )}
    </div>
  );
}

PhoneNumberInput.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputLabelHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  //  events
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  //  boolean
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  hasButton: PropTypes.bool,
  inputTheme: PropTypes.string,
  button: PropTypes.element,
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string,
  countryInputName: PropTypes.string,
  countryCode: PropTypes.string,
};

PhoneNumberInput.defaultProps = {
  onClick: undefined,
  onFocus: undefined,
  onKeyDown: undefined,
  disabled: true,
  hasButton: false,
  errorText: 'Please provide valid phone number.',
  placeholder: 'My Phone Number',
  inputLabelHeight: 'auto',
  width: 'auto',
  height: 'auto',
  inputTheme: '',
  button: undefined,
  name: 'phone',
  countryInputName: 'country',
  countryCode: '',
};
