import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import InputLabel from '../InputLabel/InputLabel';
import OTPInput, { OTPInputTheme } from '../OTPInput/OTPInput';
import Button, { buttonTheme } from '../Button/Button';
import Loader from '../Loader/Loader';

export default function OTPModal(props) {
  const {
    toggleModal,
    height,
    width,
    title,
    onChange,
    value,
    hasErrored,
    bottomText,
    labelText,
    separator,
    isInputNum,
    formik,
    onSubmit,
    loading,
  } = props;
  return (
    <>
      <Modal
        toggleModal={toggleModal}
        height={height}
        width={width}
        title={title}
      >
        <div>
          <div className="py-4">
            <InputLabel
              theme="text-sm text-gray-800 font-light"
              label="Enter verification code"
              width="auto"
              height="auto"
            />
          </div>
          <div className="otp-input">
            <OTPInput
              formik={formik}
              name="code"
              inputStyle={OTPInputTheme.inputStyle}
              focusStyle={OTPInputTheme.focusStyle}
              errorStyle={OTPInputTheme.errorStyle}
              hasErrored={hasErrored}
              shouldAutoFocus={true}
              separator={separator}
              numInputs={6}
              labelText={labelText}
              errorMessage="Wrong verification code."
              onChange={onChange}
              value={value}
              bottomText={bottomText}
              isInputNum={isInputNum}
            />
          </div>
          <div className="py-5 w-full ">
            <Button
              onClick={onSubmit}
              type="submit"
              label="Submit"
              primary={true}
              theme={buttonTheme.primary}
              disabled={(formik.values.code || '').length < 6}
              isLoading={loading}
              loader={<Loader height={15} width={15} color="white" />}
              width="100%"
              height="41px"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

OTPModal.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  hasErrored: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottomText: PropTypes.element.isRequired,
  labelText: PropTypes.string.isRequired,
  separator: PropTypes.string,
  isInputNum: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.objectOf(PropTypes.any).isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

OTPModal.defaultProps = {
  value: '',
  separator: '-',
  width: '350px',
  height: 'auto',
  title: 'Verify',
  isInputNum: true,
  loading: false,
};
