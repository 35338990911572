import React from 'react';
import { GoogleLogin as GoogleLoginPlugin } from 'react-google-login';
import PropTypes from 'prop-types';
import './google.css';

const GoogleLogin = ({
  clientId,
  onSuccess,
  onFailure,
  redirectUri,
  onClick,
}) => (
  <GoogleLoginPlugin
    clientId={clientId}
    buttonText="Continue with Google"
    uxMode="redirect"
    redirectUri={redirectUri}
    onClick={onClick}
    onSuccess={onSuccess}
    onFailure={onFailure}
    isSignedIn={false}
    cookiePolicy="single_host_origin"
    style={{ fontFamily: 'Open Sans' }}
    className="custom_google_login"
  />
);

GoogleLogin.propTypes = {
  clientId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  redirectUri: PropTypes.string.isRequired,
};

GoogleLogin.defaultProps = {
  onClick: undefined,
  onSuccess: undefined,
  onFailure: undefined,
};

export default GoogleLogin;
