/* eslint-disable */
import React from 'react';

function Layout(props) {
  return (
    <div className="h-screen md:bg-gray-200 xl:px-56 lg:px-26 md:px-14 flex justify-center items-center">
      <div className="bg-white w-full flex overflow-auto md:rounded-xl sm:h-4/5 h-full">
        <img
          src="./assets/img/doc_img.svg"
          alt="doctor logo"
          className="h-full md:block hidden"
        />
        {props.children}
      </div>
    </div>
  );
}

export default Layout;
