import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as apiService from '@doctoroncallcom/api';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import * as configUrls from '../../utils/configUrls';
import googleRecaptchaFunc from '../../utils/recaptcha';
import GoogleLogin from '../../components/GoogleLogin';
import RegAlertModal from '../../components/AlertModal/AlertModal';
import URL from '../../utils/url';
import Header from '../../components/Layout/Header';
import Layout from '../../components/Layout/Layout';
import Button, { buttonTheme } from '../../components/Button/Button';
import PhoneNumberInput from '../../components/PhoneInput/PhoneInput';
import useApiCall from '../../hooks/useApiCall';
import Loader from '../../components/Loader/Loader';
import routes from '../../config/routes';
import ErrorResponse from '../../components/ErrorResponse/ErrorResponse';
import ErrorDisplayInline from '../../components/ErrorDisplayInline/ErrorDIsplayInline';
import setRedirectUri from '../../components/SetRedirectUri/SetRedirectUri';
import GoBackAndInfo from '../../components/BackAndInfo/BackAndInfo';
import * as session from '../../utils/session';

function LoginWithSMS() {
  const navigate = useNavigate();
  const [params, setParams] = useState(null);
  const [showRegModal, setShowRegModal] = useState(false);
  const { loading, data, error, success } = useApiCall(
    params,
    apiService.user.loginPhone
  );
  const [recaptchaError, setRecaptchaError] = useState('');
  const [recaptchaLoading, setRecaptchaLoading] = useState(false);
  const [showGoogleBtn, setShowGoogleBtn] = useState(true);

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      country: 'MY',
      countryCode: '',
      generalError: '',
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().required('Required*'),
    }),
    onSubmit: (values) => {
      setRecaptchaLoading(true);
      googleRecaptchaFunc()
        .then((token) => {
          setRecaptchaLoading(false);
          setParams({
            countryCode: `+${values.countryCode}`,
            phoneNumber: values.phoneNumber.replace(
              `+${values.countryCode}`,
              ''
            ),
            type: 'SMS',
            recaptchaToken: token,
          });
        })
        .catch((errToken) => {
          setRecaptchaLoading(false);
          setRecaptchaError(errToken);
        });
    },
  });

  useEffect(() => {
    if (success) {
      const url = new URL(routes.loginPhoneVerify)
        .appendParams({
          sessionId: data.data.sessionId,
          phone: formik.values.phoneNumber,
          country: formik.values.country,
          countryCode: formik.values.countryCode,
          type: params.type,
        })
        .getUrl();
      navigate(url);
    }
  }, [success]);

  useEffect(() => {
    /* eslint-disable no-console */
    if (error || recaptchaError) {
      const err = error || recaptchaError;
      const errorResponse = ErrorResponse(err);
      if (err.code === 401) {
        formik.setErrors({
          code: 'Incorrect code entered.',
        });
      }
      if (errorResponse === 'not-registered') {
        setShowRegModal(true);
      } else {
        formik.setErrors({
          generalError: errorResponse,
        });
      }
    }
  }, [error, recaptchaError]);

  useEffect(() => {
    setRedirectUri();
    const urlParams = new URL().getParams();
    if (urlParams?.error && urlParams?.error === 'permission-denied') {
      formik.setErrors({
        generalError: 'Permission Denied.',
      });
    } else {
      session.setGoogleBtnUri(
        `${window.location.origin}${window.location.pathname}`
      );
    }

    const redirectUri = session.getRedirectUri() || '';
    if (redirectUri.includes('docpod')) {
      setShowGoogleBtn(false);
    }
  }, []);

  return (
    <Layout>
      <div className="flex flex-col items-center h-full w-full pb-4">
        <GoBackAndInfo showBack={false} />
        <div className="w-full justify-center flex-col flex flex-1 items-center h-full">
          <Header />
          <main className="flex justify-center items-center pt-10 flex-col px-5 md:pt-5 lg:w-3/5">
            <form onSubmit={formik.handleSubmit} className="w-full">
              <div className="mb-4 w-full">
                <PhoneNumberInput
                  formik={formik}
                  width="100%"
                  height="41px"
                  name="phoneNumber"
                  countryInputName="country"
                  countryCode="countryCode"
                  errorText="Please provide a valid phone number*"
                  disabled={false}
                  inputLabelHeight="30px"
                />
              </div>

              <div className="py-3 w-full ">
                <Button
                  type="submit"
                  label="Continue with SMS"
                  primary={true}
                  theme={buttonTheme.primary}
                  isLoading={loading || recaptchaLoading}
                  loader={<Loader height={15} width={15} color="white" />}
                  disabled={loading || recaptchaLoading}
                  width="100%"
                  height="41px"
                />
              </div>
              <div className=" w-full text-center text-theme-gray font-light">
                Or
              </div>
              {showGoogleBtn && (
                <div className="py-2 w-full ">
                  <GoogleLogin
                    clientId={configUrls.REACT_APP_GOOGLE_CLIENT_ID}
                    redirectUri={`${window.location.origin}${routes.callbackGoogle}`}
                  />
                </div>
              )}
              <div className={`${showGoogleBtn ? 'pt-0' : 'pt-2'} pb-2 w-full`}>
                <Button
                  type="submit"
                  label="Continue with Email / Password"
                  primary={false}
                  theme={buttonTheme.secondary}
                  /* eslint-disable */
                  disabled={loading}
                  width="100%"
                  height="41px"
                  icon="./assets/icons/security-lock-icon.svg"
                  iconPosition="left"
                  onClick={() => navigate('/login-email')}
                />
              </div>
              {showRegModal && (
                <RegAlertModal
                  onCancel={() => setShowRegModal(false)}
                  onConfirm={() => {
                    navigate(
                      new URL(routes.register)
                        .appendParams({
                          code: formik.values.code,
                          phoneNumber: formik.values.phoneNumber,
                          country: formik.values.country,
                          countryCode: formik.values.countryCode,
                        })
                        .getUrl()
                    );
                  }}
                  width="328px"
                  height="196px"
                  title=""
                />
              )}
              {formik.errors?.generalError ? (
                <>
                  <br />
                  <ErrorDisplayInline error={formik.errors.generalError} />
                </>
              ) : null}
            </form>
            <div className="pt-3 font-normal text-gray-600 text-center px-14">
              <p>
                By Clicking on Sign In, you agree to our{' '}
                <a
                  className="text-theme-primary cursor-pointer leading-6"
                  href={'https://www.doctoroncall.com.my/termsOfService'}
                >
                  Terms & Conditions
                </a>
              </p>
            </div>
          </main>
        </div>
        <div className="flex-1"> </div>
        <div className="flex-1"> </div>
      </div>
    </Layout>
  );
}

export default LoginWithSMS;
