import React from 'react';
import { Helmet } from 'react-helmet';
import Routing from '../Routing';

function Index() {
  return (
    <div className="app">
      {/* Google tag manager */}
      {window.location?.href?.includes('doctoroncall.com.my') ? (
        <Helmet>
          {/* eslint-disable */}
          {/* <!-- Google Tag Manager --> */}
          <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5TJCHWZ');`}</script>
            {/* <!-- End Google Tag Manager --> */}
        </Helmet>)
        :
        <Helmet>
          {/* <!-- Google Tag Manager --> */}
          <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-T3BVP88');`}</script>
          {/* <!-- End Google Tag Manager --> */} 
        </Helmet>
      }
      <Helmet>
        <script src='//cdnt.netcoresmartech.com/smartechclient.js'></script>
    </Helmet>
        
      {/* <!-- Google Tag Manager (noscript) --> */}
      {window.location?.href?.includes('doctoroncall.com.my') ? (
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-5TJCHWZ"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>
        </noscript>
      ) : (
        <noscript>
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T3BVP88"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
        </noscript>
      )}

      
      {/* <!-- VISA Tracking Code for https://www.doctoroncall.com.my/ --> */}
      {window.location?.href?.includes('doctoroncall.com.my') ?
        <Helmet>
          <script>{`(function(v,i,s,a,t){v[t]=v[t]||function(){(v[t].v=v[t].v||[]).push(arguments)};if(!v._visaSettings){v._visaSettings={}}v._visaSettings[a]={v:'1.0',s:a,a:'1',t:t};var b=i.getElementsByTagName('body')[0];var p=i.createElement('script');p.defer=1;p.async=1;p.src=s+'?s='+a;b.appendChild(p)})(window,document,'//app-worker.visitor-analytics.io/main.js','566eefa1-ff3e-11eb-b589-901b0edac50a','va')`}</script>
        </Helmet>
      : null}
      <Routing />
    </div>
  );
}

export default Index;
