const decodeToken = (token) => {
  /* eslint-disable */
  const Buff = new Buffer.from(token, 'base64');
  try {
    return JSON.parse(Buff.toString('ascii'));
  } catch (e) {
    console.log('Token is not valid. Please check.');
    return;
  }
};

export default decodeToken;
