import React from 'react';
import PropTypes from 'prop-types';

function CorporateVerifyHeader({ scenerioType }) {
  return (
    <div className="w-full flex flex-col items-center">
      <div className=" text-center flex-col flex">
        {/* eslint-disable */}
        <svg className="h-28 md:block m-auto" width="156" height="123" viewBox="0 0 156 123" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M66.1253 116.394C64.8775 115.881 63.4066 116.485 62.8899 117.734C62.3745 118.98 62.9834 120.455 64.2301 120.97C65.4766 121.485 66.9498 120.877 67.4655 119.63C67.9805 118.383 67.3741 116.909 66.1253 116.394Z" fill="#0079FF" fillOpacity="0.24"></path>
          <path d="M11.6447 42.0906C13.6609 42.9216 15.7455 40.8031 14.8553 38.7995C14.428 37.8376 13.3996 37.2285 12.3486 37.3378C11.3173 37.4452 10.4437 38.1951 10.1894 39.2017C9.89141 40.3804 10.528 41.6238 11.6447 42.0906Z" fill="#0079FF"></path>
          <path d="M149.53 53.8359H145.566V57.7996H149.53V53.8359Z" fill="#0079FF" fillOpacity="0.24"></path>
          <path d="M8.98941 84.4521L6.4954 81.3713L3.41469 83.8654L5.9087 86.9461L2.828 89.4401L5.32201 92.5208L8.40272 90.0268L10.8967 93.1075L13.9774 90.6135L11.4834 87.5328L14.5641 85.0387L12.0701 81.958L8.98941 84.4521Z" fill="#0079FF"></path>
          <path d="M150.437 103.837L153.556 101.392L151.111 98.272L147.991 100.717L145.546 97.5974L142.427 100.043L144.872 103.162L141.752 105.607L144.197 108.727L147.317 106.282L149.762 109.402L152.882 106.956L150.437 103.837Z" fill="#0079FF"></path>
          <path d="M66.2252 52.7141C79.8049 52.7141 90.8137 41.706 90.8137 28.1256C90.8137 14.5459 79.8049 3.53711 66.2252 3.53711C52.6455 3.53711 41.6367 14.5459 41.6367 28.1256C41.6498 41.7009 52.6506 52.701 66.2252 52.7141ZM66.2252 8.18949C77.2361 8.18949 86.1613 17.1161 86.1613 28.1256C86.1613 39.1365 77.2361 48.0617 66.2252 48.0617C55.215 48.0617 46.2898 39.1365 46.2898 28.1256C46.3022 17.1204 55.2201 8.20258 66.2252 8.18949Z" fill="#0079FF"></path>
          <path d="M130.128 66.4635L110.076 55.7863C109.385 55.4096 108.548 55.4183 107.866 55.8089L89.5113 65.8584C82.7883 60.8569 74.7388 58.2518 66.1795 58.2518C55.5249 58.2751 45.6383 62.4162 38.3103 69.953C30.8892 77.5595 26.8179 87.9581 26.8419 99.2404C26.8455 100.524 27.8848 101.563 29.1677 101.567L93.8844 101.451C96.9266 105.228 100.838 108.213 105.283 110.151L107.982 111.337C108.275 111.468 108.593 111.531 108.913 111.523C109.231 111.518 109.547 111.455 109.843 111.337L112.89 110.011C124.087 105.314 131.369 94.3545 131.361 82.2119V68.5333C131.362 67.6693 130.888 66.8744 130.128 66.4635ZM31.5641 96.8913C32.0521 87.7487 35.6114 79.3981 41.6601 73.1864C48.1031 66.5566 56.8268 62.9042 66.2021 62.9042H66.2719C73.9723 62.9042 81.1839 65.3238 87.1622 69.9064V82.4912C87.1658 87.4876 88.4123 92.404 90.7905 96.7974L31.5641 96.8913ZM126.708 82.2119C126.713 92.4927 120.538 101.768 111.052 105.731H111.029L108.913 106.638L107.144 105.87C97.8407 101.805 91.8247 92.6214 91.8145 82.4686V69.8831L109.005 60.462L126.708 69.9064V82.2119Z" fill="#0079FF"></path>
          <path d="M102.631 80.9776C101.796 80.0015 100.328 79.8874 99.3509 80.7223C98.3749 81.5572 98.2607 83.0256 99.0956 84.0016L104.376 90.166C104.819 90.6816 105.464 90.9783 106.144 90.9805C106.677 90.9849 107.195 90.8045 107.609 90.4685L119.892 80.4896C120.888 79.6735 121.033 78.2052 120.217 77.2088C119.402 76.2132 117.934 76.0677 116.938 76.8837L106.423 85.4445L102.631 80.9776Z" fill="#0079FF"></path>
          <path d="M35.5965 36.3086H31.6328V40.2723H35.5965V36.3086Z" fill="#0079FF" fillOpacity="0.24"></path>
          <path d="M135.759 23.7891H131.795V27.7528H135.759V23.7891Z" fill="#0079FF"></path>
        </svg>
        <br />
        <h1 className="text-theme-gray font-normal text-base leading-7 md:block px-4">
          {
            scenerioType === 'token' ?
            'Your account is registered with the following email and mobile number.'
            : 'Kindly ensure that you use your corporate or group insurance email address, rather than your personal email'
          }
        </h1>
      </div>
    </div>
  );
}

export default CorporateVerifyHeader;
CorporateVerifyHeader.propTypes = {
  scenerioType: PropTypes.string,
};
CorporateVerifyHeader.defaultProps = {
  scenerioType: 'token',
};
