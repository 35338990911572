import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { inputTheme } from '../Input/Input';

const CustomInput = React.forwardRef(
  (
    {
      value,
      onClick,
      onBlur,
      placeholder,
      disabled,
      readOnly,
      onFocus,
      id,
      name,
      required,
    },
    ref
  ) => (
    <div
      className={classNames(
        'flex items-center justify-between relative',
        inputTheme.plain
      )}
    >
      <div className="w-full h-full">
        <input
          className="outline-none w-full h-full text-theme-gray placeholder:text-xs"
          value={value}
          ref={ref}
          onClick={onClick}
          onBlur={onBlur}
          placeholder={placeholder}
          onFocus={onFocus}
          style={{ width: '100%', height: '40px' }}
          disabled={disabled}
          readOnly={readOnly}
          id={id}
          name={name}
          required={required}
        />
      </div>
      <div onClick={onClick} onBlur={onBlur}>
        <img
          src="./assets/icons/calendar-icon.svg"
          alt="calendar"
          className="cursor-pointer"
        />
      </div>
    </div>
  )
);
CustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  onFocus: PropTypes.func,
};
CustomInput.defaultProps = {
  value: '',
  onClick: undefined,
  placeholder: '',
  readOnly: false,
  required: false,
  onFocus: undefined,
  onBlur: undefined,
  id: 'calendar',
  name: 'calendar',
  disabled: false,
};

export default CustomInput;
