import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import Button, { iconButtonPosition } from '../Button/Button';
import CalendarInput from '../Calendar/Calendar';
import CustomInput from '../Calendar/CustomInput';
import Input, { inputTheme } from '../Input/Input';
import InputLabel, { inputLabelTheme } from '../InputLabel/InputLabel';
import Loader from '../Loader/Loader';
import OTPInput from '../OTPInput/OTPInput';
import PhoneNumberInput from '../PhoneInput/PhoneInput';
import Dropdown from '../Dropdown/Dropdown';
import Countries from './countries.json';

const genderOptionsList = [
  { value: '', name: 'Select Gender' },
  { value: 'male', name: 'Male' },
  { value: 'female', name: 'Female' },
];

const radioClassName = `h-5 w-5 border-gray-300 focus:ring-2 focus:ring-blue-300 mr-2 pt-3`;

const CompleteProfileForm = ({
  formik,
  verifyPhoneAndDispatchCode,
  showOtp,
  resendCode,
  counter,
  onResend,
  setShowVerificationOption,
  isContinueDisabled,
  type,
  step,
  handleContinue,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCancel = () => {
    const redirectUri = searchParams.get('redirectUri');

    window.location.href = redirectUri;
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex justify-center items-center flex-col w-full phone-con px-1"
    >
      {step === 1 ? (
        <>
          <div className="pb-3 w-full">
            <Input
              theme={inputTheme.plain}
              type="text"
              disabled={false}
              readOnly={false}
              formik={formik}
              name="name"
              id="name"
              height="41px"
              width="100%"
              placeholder="Full name (as per your NRIC/Passport)*"
              label={
                <InputLabel
                  label="Full Name"
                  theme={inputLabelTheme.default}
                  height="auto"
                  width="auto"
                />
              }
            />
          </div>
          <div className="pb-3 w-full">
            <Input
              theme={inputTheme.plain}
              type="text"
              disabled={false}
              readOnly={false}
              formik={formik}
              name="icOrPassport"
              id="icOrPassport"
              height="41px"
              width="100%"
              placeholder="NRIC/Passport number"
              label={
                <InputLabel
                  label="Your NRIC or Passport"
                  theme={inputLabelTheme.default}
                  height="auto"
                  width="auto"
                />
              }
            />
          </div>
          <div className="w-full mb-6">
            <InputLabel
              label="Date of Birth"
              theme={inputLabelTheme.default}
              height="auto"
              width="auto"
            />
            <CalendarInput
              onBlur={formik.handleBlur}
              formik={formik}
              readOnly={false}
              customInput={<CustomInput />}
              calendarClassName="defaultCalendar"
              name="dob"
              id="dob"
              height="40px"
              width="100%"
              className="w-full"
              placeholderText="DD-MM-Y"
              // label={

              // }
            />
          </div>
          <div className="w-full flex mb-6">
            <div className="mx-1 flex items-center">
              <input
                type="radio"
                className={radioClassName}
                onChange={formik.handleChange}
                checked={formik.values.gender === 'male'}
                name="gender"
                value="male"
                id="male"
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="male">Male</label>
            </div>
            <div className="mx-1 flex items-center">
              <input
                type="radio"
                className={radioClassName}
                onChange={formik.handleChange}
                checked={formik.values.gender === 'female'}
                name="gender"
                value="female"
                id="female"
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="female">Female</label>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="pb-3 w-full">
            <Input
              theme={inputTheme.plain}
              label={
                <InputLabel
                  label="Email Address*"
                  theme={inputLabelTheme.default}
                  height="20px"
                />
              }
              type="email"
              formik={formik}
              name="email"
              id="email"
              height="41px"
              width="100%"
              placeholder="johndoe@gmail.com"
            />
          </div>
          <div className="pb-3 w-full">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              className="text-sm relative -left-2"
              htmlFor="address.street"
            >
              Your address
            </label>
            <Input
              theme={inputTheme.plain}
              label={
                <InputLabel
                  label="Home Address"
                  theme={inputLabelTheme.default}
                  height="20px"
                />
              }
              type="text"
              formik={formik}
              name="address.street"
              id="address.street"
              height="41px"
              width="100%"
              placeholder="No 23, Jalan Sultan Mansor, Taman Suci Murni"
            />
            <div className="flex flex-col lg:flex-row lg:gap-2.5 custom-class-second">
              <div className="flex flex-row gap-2.5">
                <div className="w-full">
                  <Input
                    theme={inputTheme.plain}
                    label={
                      <InputLabel
                        label="Postcode"
                        theme={inputLabelTheme.default}
                        height="20px"
                      />
                    }
                    type="text"
                    formik={formik}
                    name="address.zipCode"
                    id="address.zipCode"
                    height="41px"
                    width="100%"
                    placeholder="12788"
                  />
                </div>
                <div className="w-full">
                  <Input
                    theme={inputTheme.plain}
                    label={
                      <InputLabel
                        label="City"
                        theme={inputLabelTheme.default}
                        height="20px"
                      />
                    }
                    type="text"
                    formik={formik}
                    name="address.city"
                    id="address.city"
                    height="41px"
                    width="100%"
                    placeholder="Kuala Langat"
                  />
                </div>
              </div>
              <Dropdown
                options={Countries}
                formik={formik}
                name="address.country"
                id="address.country"
                disabled={false}
                multiple={false}
                required={true}
                value={formik.values.address.city}
                onChange={formik.handleChange}
                size=""
                className={inputTheme.plain}
                style={{ height: '42px', paddingLeft: '0px', width: '100%' }}
                width="100%"
                label={
                  <InputLabel
                    label="Country"
                    theme={inputLabelTheme.default}
                    height="20px"
                  />
                }
              />
              {/* <Input
                theme={inputTheme.plain}
                label={
                  <InputLabel
                    label="Country"
                    theme={inputLabelTheme.default}
                    height="20px"
                  />
                }
                type="text"
                formik={formik}
                name="address.country"
                id="address.country"
                height="41px"
                width="100%"
                placeholder="Kuala Langat"
              /> */}
            </div>
          </div>
          <div className="pb-3 w-full">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="text-xs" htmlFor="phone">
              Mobile Number
            </label>
            <div className="flex flex-row sm:gap-2 gap-1">
              <div className="sm:w-full w-9/12">
                <PhoneNumberInput
                  // inputTheme="max-w-full"
                  // width="100%"
                  formik={formik}
                  height="auto"
                  name="phoneNumber"
                  countryInputName="country"
                  countryCode="countryCode"
                  // containerClass="w-full h-full"
                  errorText="Please provide a valid phone number*"
                  placeholder="Mobile number"
                  enableSearch={true}
                  disableSearchIcon={true}
                  disableDropdown={false}
                  disabled={false}
                  autoFormat={true}
                  hasButton={true}
                  inputLabelHeight="30px"
                  // containerStyle={{
                  //   border: 'none',
                  //   // width: '100%',
                  //   height: '100%',
                  // }}
                />
              </div>
              <div className="w-full sm:w-20">
                <Button
                  label="Verify"
                  type="button"
                  primary={true}
                  width="auto"
                  minWidth="30px"
                  height="40px"
                  onClick={verifyPhoneAndDispatchCode}
                  centerContent={true}
                  disabled={
                    formik.errors.phoneNumber &&
                    Object.keys(formik.errors.phoneNumber).length > 0
                  }
                />
              </div>
            </div>
          </div>
          {showOtp && (
            <div className="pb-3 w-full ">
              <OTPInput
                formik={formik}
                isInputNum={true}
                numInputs={6}
                name="code"
                separator=""
                hasErrored={false}
                shouldAutoFocus={true}
                inputLabelHeight="auto"
                errorMessage="Wrong verification code."
                labelText={`We have sent a 6 digit verification code ${
                  type === 'WHATSAPP' ? 'on WhatsApp' : ''
                } to ${formik.values.phoneNumber}`}
                containerStyle={{
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              />
              {/* {type !== 'CALL' && (
                
              )} */}
              {/* not using it as bottom text inside the otp input because we have to hide the otp input for call. */}
              {type === 'CALL' && (
                <div className="text-sm text-theme-second-font">
                  You will receive the verification code via call on
                  {formik.values.phoneNumber} shortly.
                </div>
              )}
              {resendCode ? (
                <p className="text-sm text-gray-800 font-light pt-2 leading-5">
                  Please wait{' '}
                  <span className="text-theme-text-error font-semibold">
                    {counter} seconds
                  </span>{' '}
                  to resend.
                </p>
              ) : (
                <p className="text-sm text-gray-800 font-semibold pt-2 leading-5">
                  Did not receive the code?{' '}
                  <span
                    className="text-blue-500 cursor-pointer"
                    onClick={() => onResend('SMS')}
                  >
                    Resend
                  </span>{' '}
                  or try a{' '}
                  <span
                    className="text-blue-500 cursor-pointer"
                    onClick={() => setShowVerificationOption(true)}
                  >
                    different verification method
                  </span>
                </p>
              )}
            </div>
          )}
        </>
      )}
      <div className="pb-3 w-full mt-3 flex gap-2">
        <Button
          height="40px"
          iconPosition="right"
          label="Cancel"
          onClick={handleCancel}
          theme="bg-white text-black border border-solid text-theme-gray p-1 border-theme-line"
          type="button"
          width="25%"
          minWidth={90}
        />

        <Button
          type="submit"
          label="Continue"
          // icon="/assets/icons/continue-icon.svg"
          iconPosition={iconButtonPosition.center}
          primary={true}
          // theme={buttonTheme.primary
          isLoading={false}
          loader={<Loader height={15} width={15} />}
          disabled={isContinueDisabled}
          width="100%"
          height="40px"
          centerContent={true}
          onClick={step === 1 ? handleContinue : () => {}}
        />
      </div>
    </form>
  );
};

CompleteProfileForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.any.isRequired,
  verifyPhoneAndDispatchCode: PropTypes.func,
  showOtp: PropTypes.bool.isRequired,
  resendCode: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  counter: PropTypes.any.isRequired,
  onResend: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setShowVerificationOption: PropTypes.any.isRequired,
  isContinueDisabled: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  handleContinue: PropTypes.func.isRequired,
};

CompleteProfileForm.defaultProps = {
  verifyPhoneAndDispatchCode: () => {},
};

export default CompleteProfileForm;
