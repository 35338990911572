import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import * as apiService from '@doctoroncallcom/api';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Button, { buttonTheme } from '../../components/Button/Button';
import Input, { inputTheme } from '../../components/Input/Input';
import InputLabel, {
  inputLabelTheme,
} from '../../components/InputLabel/InputLabel';
import Layout from '../../components/Layout/Layout';
import ForgetPasswordHeader from '../../components/Layout/ForgetPasswordHeader';
import URL from '../../utils/url';
import useApiCall from '../../hooks/useApiCall';
import Loader from '../../components/Loader/Loader';
import routes from '../../config/routes';
import googleRecaptchaFunc from '../../utils/recaptcha';
import ErrorResponse from '../../components/ErrorResponse/ErrorResponse';
import ErrorDisplayInline from '../../components/ErrorDisplayInline/ErrorDIsplayInline';
import VerificationMethodModal from '../../components/VerificationMethodModal/OTPModal';
import GoBackAndInfo from '../../components/BackAndInfo/BackAndInfo';

function VerifyEmail() {
  const navigate = useNavigate();
  const urlParams = new URL().getParams();
  const [resendCode, setResendCode] = useState(true);
  const [otpParams, setOtpParams] = useState(null);
  const otpApi = useApiCall(otpParams, apiService.otp.code);
  const [counter, setCounter] = useState(60);
  const [recaptchaError, setRecaptchaError] = useState('');
  const [recaptchaLoading, setRecaptchaLoading] = useState(false);
  const [showVerificationOption, setShowVerificationOption] = useState(false);

  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setResendCode(false);
    }
    return () => clearInterval(timer);
  }, [counter]);

  const formik = useFormik({
    initialValues: {
      info: `${urlParams.info}`,
      code: '',
      generalError: '',
    },

    validationSchema: Yup.object().shape({
      info: Yup.string(),
      code: Yup.string().required('Required*'),
    }),
    onSubmit: (values) => {
      const url = new URL(routes.resetPassword)
        .appendParams({
          sessionId: urlParams.sessionId,
          code: values.code,
          email: urlParams.email,
          errorCode: null,
        })
        .getUrl();
      navigate(url);
    },
  });
  const onResend = (type) => {
    if (counter === 0) {
      setRecaptchaLoading(true);
      googleRecaptchaFunc()
        .then((token) => {
          setRecaptchaLoading(false);
          if (urlParams.type === 'SMS') {
            setOtpParams({
              countryCode: `${urlParams.cc}`,
              phoneNumber: urlParams.info.includes('+6')
                ? urlParams.info.split(urlParams.cc)[1]
                : urlParams.info,
              type: type ?? 'SMS',
              recaptchaToken: token,
            });
          } else {
            setOtpParams({
              email: formik.values.info || urlParams.info,
              type: 'EMAIL',
              recaptchaToken: token,
            });
          }
        })
        .catch((errToken) => {
          setRecaptchaLoading(false);
          setRecaptchaError(errToken);
        });
      setResendCode(true);
      setCounter(60);
    }
  };

  const OTPBottomText = () =>
    resendCode ? (
      <p className="text-base text-gray-800 font-light pt-2 leading-5">
        Please wait{' '}
        <span className={classNames(inputTheme.error, 'text-base')}>
          {counter} seconds
        </span>{' '}
        to resend.
      </p>
    ) : (
      <p className="text-base text-gray-800 font-normal pt-2 leading-5">
        Did not received the code?{' '}
        <span
          className="text-theme-primary cursor-pointer"
          onClick={() => onResend(otpParams?.type ?? urlParams.type)}
        >
          Resend
        </span>{' '}
        {urlParams.type === 'SMS' ? (
          <>
            or try a{' '}
            <span
              className="text-theme-primary cursor-pointer"
              onClick={() => setShowVerificationOption(true)}
            >
              different verification method
            </span>
          </>
        ) : null}
      </p>
    );

  const { errorCode } = urlParams;
  useEffect(() => {
    if (errorCode) {
      const errorResponse = ErrorResponse(errorCode);
      if (errorCode === '401') {
        formik.setErrors({ code: 'Incorrect code entered.', email: '' });
        formik.setTouched({ code: 'true' }, false);
      }
      formik.setErrors({
        generalError: errorResponse,
      });
    }
  }, [errorCode]);

  // combine these 2 functions later
  useEffect(() => {
    /* eslint-disable no-console */
    if (otpApi.error || recaptchaError) {
      const err = otpApi.error || recaptchaError;
      const errorResponse = ErrorResponse(err);
      if (err.code === 401) {
        formik.setErrors({
          code: 'Incorrect code entered.',
        });
      } else {
        formik.setErrors({
          generalError: errorResponse,
        });
      }
    }
  }, [otpApi.error, recaptchaError]);

  const { success: otpSuccess } = otpApi;
  useEffect(() => {
    if (otpSuccess) {
      const url = new URL(routes.forgotPasswordVerify)
        .appendParams({
          sessionId: otpApi.data.data.sessionId,
          code: formik.values.code,
          email: urlParams.email,
          errorCode: null,
        })
        .getUrl();
      navigate(url);
    }
  }, [otpSuccess]);

  return (
    <Layout>
      <div className="w-full items-center h-full flex-col flex">
        <GoBackAndInfo />
        <main className="flex flex-col px-5 sm:pt-24 pt-10 lg:w-3/5 md:w-full sm:w-3/5 w-full">
          <ForgetPasswordHeader text="Verify Your Email / Phone number" />
          <form onSubmit={formik.handleSubmit} className="w-full pt-3">
            <div className="pb-2 w-full">
              <Input
                theme={inputTheme.plain}
                label={
                  <InputLabel
                    label="Contact Info"
                    theme={inputLabelTheme.default}
                    height="30px"
                  />
                }
                type="text"
                disabled={true}
                readOnly={false}
                formik={formik}
                name="info"
                id="info"
                height="41px"
                width="100%"
                placeholder=""
              />
            </div>
            <div className="pb-2 w-full">
              <Input
                theme={classNames(inputTheme.plain, 'pr-0')}
                label={
                  <InputLabel
                    label="OTP"
                    theme={inputLabelTheme.default}
                    height="30px"
                  />
                }
                type="text"
                disabled={false}
                readOnly={false}
                formik={formik}
                name="code"
                id="code"
                height="41px"
                width="100%"
                placeholder="Enter received OTP"
                // button={
                //   <Button
                //     primary={true}
                //     label={!resendCode ? 'Resend' : `Resend in ${counter}`}
                //     height="100%"
                //     width="80%"
                //     theme={buttonTheme.primary}
                //     icon=""
                //     iconPosition=""
                //     disabled={counter > 0}
                //     onClick={onResend}
                //     type="button"
                //   />
                // }
              />
              {OTPBottomText()}
            </div>
            <div className="pt-6 w-full ">
              <Button
                type="submit"
                label="Verify OTP"
                primary={true}
                theme={buttonTheme.primary}
                isLoading={otpApi.loading || recaptchaLoading}
                loader={<Loader height={15} width={15} color="white" />}
                disabled={otpApi.loading || recaptchaLoading}
                width="100%"
                height="41px"
              />
            </div>
            <br />
            {formik.errors?.generalError ? (
              <ErrorDisplayInline error={formik.errors.generalError} />
            ) : null}
          </form>
          {showVerificationOption && (
            <VerificationMethodModal
              toggleModal={() => setShowVerificationOption(false)}
              onChange={(type) => {
                onResend(type);
                setShowVerificationOption(false);
              }}
            />
          )}
        </main>
        <div className="flex-1"> </div>
        <div className="flex-1"> </div>
      </div>
    </Layout>
  );
}

export default VerifyEmail;
