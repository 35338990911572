/* eslint-disable */

export const errorHandler = (error) => {
  const err = {};
  for (let index = 0; index < error.errors.length; index++) {
    err[error.errors[index].property] = [
      error.errors[index].message,
    ].toString();
  }
  return err;
};
