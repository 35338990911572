import React from 'react';

const Overlay = () => (
  <div className="fixed top-0 left-0 h-screen w-full flex items-center justify-center bg-black opacity-50 z-50">
    <div className="inner">
      <img src="./assets/img/doc_logo_img.svg" alt="DoC Logo" width="140px" />
      <div className="loader">&nbsp;</div>
    </div>
  </div>
);

export default Overlay;
