import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as apiService from '@doctoroncallcom/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import URL from '../../utils/url';
import Button, { buttonTheme } from '../../components/Button/Button';
import { inputTheme } from '../../components/Input/Input';
import Header from '../../components/Layout/Header';
import Layout from '../../components/Layout/Layout';
import OTPInput from '../../components/OTPInput/OTPInput';
import useApiCall from '../../hooks/useApiCall';
import PhoneNumberInput from '../../components/PhoneInput/PhoneInput';
import routes from '../../config/routes';
import Loader from '../../components/Loader/Loader';
import * as session from '../../utils/session';
import { redirect } from '../../utils/redirect';
import googleRecaptchaFunc from '../../utils/recaptcha';
import ErrorResponse from '../../components/ErrorResponse/ErrorResponse';
import ErrorDisplayInline from '../../components/ErrorDisplayInline/ErrorDIsplayInline';
import GoBackAndInfo from '../../components/BackAndInfo/BackAndInfo';
import checkUserRole from '../../utils/checkUserRole';

function LoginVerification() {
  const navigate = useNavigate();
  const [params, setParams] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState('');
  const [recaptchaLoading, setRecaptchaLoading] = useState(false);
  const { loading, data, error, success } = useApiCall(
    params,
    apiService.user.loginOtp
  );
  const [optParams, setOtpParams] = useState(null);
  const otpApi = useApiCall(optParams, apiService.otp.code);

  const urlParams = new URL().getParams();

  const formik = useFormik({
    initialValues: {
      phone: `${urlParams.phone}`,
      code: '',
      country: `${urlParams.country}`,
      countryCode: `${urlParams.countryCode}`,
      generalError: '',
    },
    validationSchema: Yup.object().shape({
      phone: Yup.string().required('Required*'),
      code: Yup.string().required('Required*'),
    }),
    onSubmit: (values) => {
      setRecaptchaLoading(true);
      googleRecaptchaFunc()
        .then((token) => {
          setRecaptchaLoading(false);
          setParams({
            code: values.code,
            sessionId: urlParams.sessionId,
            countryCode: urlParams.countryCode,
            phoneNumber: urlParams.phone.split(urlParams.countryCode)[1],
            recaptchaToken: token,
          });
        })
        .catch((errToken) => {
          setRecaptchaLoading(false);
          setRecaptchaError(errToken);
        });
    },
  });
  const [resendCode, setResendCode] = useState(true);
  const [counter, setCounter] = useState(60);

  const onResend = () => {
    setResendCode(true);
    setCounter(60);
    setRecaptchaLoading(true);
    googleRecaptchaFunc()
      .then((token) => {
        setRecaptchaLoading(false);
        setOtpParams({
          countryCode: urlParams.countryCode,
          phoneNumber: urlParams.phone.split(urlParams.countryCode)[1],
          type: urlParams.type,
          recaptchaToken: token,
        });
      })
      .catch((errToken) => {
        setRecaptchaLoading(false);
        setRecaptchaError(errToken);
      });
  };

  useEffect(() => {
    if (otpApi.success) {
      navigate(
        new URL(routes.loginPhoneVerify)
          .appendParams({ sessionId: otpApi.data.data.sessionId })
          .getUrl()
      );
    }
  }, [otpApi.success]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setResendCode(false);
    }
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (error || recaptchaError) {
      const err = error || recaptchaError;
      const errorResponse = ErrorResponse(err);
      if (err.code === 401) {
        formik.setErrors({
          code: 'Incorrect code entered.',
        });
      }
      formik.setErrors({
        generalError: errorResponse,
      });
      setParams(null);
    }
  }, [error || recaptchaError]);

  useEffect(() => {
    if (success) {
      if (checkUserRole(data.data.user)) {
        session.storeSession(data.data);
        try {
          if (window.location?.href?.includes('doctoroncall.com.my')) {
            window.smartech(
              'create',
              'ADGMOT35CHFLVDHBJNIG50K969Q1L02D392144T1IJBB24T6SG2G'
            );
            window.smartech('register', 'bb968d0bcc5eae0646a8a3043059261e');
            window.smartech(
              'identify',
              `${data.data?.user?.countryCode}${data.data?.user?.phoneNumber}`
            );
          } else {
            window.smartech(
              'create',
              'ADGMOT35CHFLVDHBJNIG50K969Q1L02D392144T1IJBB24T6SG2G'
            );
            window.smartech('register', '48488df56ef489c753cd578ebbe5312b');
            window.smartech(
              'identify',
              `${data.data?.user?.countryCode}${data.data?.user?.phoneNumber}`
            );
          }
          window.smartech('dispatch', 'signin', {
            email: data.data?.user?.email,
          });
        } catch (e) {
          /* eslint-disable */
          console.log(`Netcore error: ${e}`);
        }
        redirect();
      } else {
        formik.setErrors({
          generalError: 'Permission Denied.',
        });
      }
    }
  }, [success]);

  return (
    <Layout>
      <div className="w-full h-full flex flex-col items-center">
        <GoBackAndInfo showBack={true} />
        <div className="w-full items-center justify-center flex-col flex">
          <Header />
          <main className="flex justify-center items-center pt-10 md:pt-5 flex-col px-5 lg:w-7/10">
            <form
              onSubmit={formik.handleSubmit}
              className="flex justify-center items-center pt-10 md:pt-5 flex-col lg:w-4/5 md:w-full sm:w-4/5 max-w-lg"
            >
              <PhoneNumberInput
                formik={formik}
                width="100%"
                height="41px"
                name="phone"
                countryInputName="country"
                countryCode="countryCode"
                containerClass="w-full h-full"
                errorText="Please provide a valid phone number*"
                enableSearch={true}
                disableSearchIcon={true}
                disableDropdown={false}
                disabled={true}
                inputLabelHeight="30px"
                autoFormat={true}
                hasButton={true}
                inputStyle={{ border: 'none', width: '100%', height: '100%' }}
                buttonStyle={{ border: 'none', height: '100%' }}
                containerStyle={{
                  border: 'none',
                  width: '100%',
                  height: '100%',
                }}
                inputTheme={classNames('pl-0')}
                inputClass={classNames('bg-gray-100 cursor-not-allowed')}
                button={
                  <Button
                    label="Change"
                    type="button"
                    theme={classNames(buttonTheme.white, 'rounded-none')}
                    primary={true}
                    width="auto"
                    minWidth="75px"
                    height="100%"
                    onClick={() => {
                      navigate('/');
                    }}
                  />
                }
              />
              <div className="py-5 w-full ">
                <OTPInput
                  formik={formik}
                  isInputNum={true}
                  numInputs={6}
                  name="code"
                  separator=""
                  hasErrored={false}
                  shouldAutoFocus={true}
                  inputLabelHeight="auto"
                  errorMessage="Wrong verification code."
                  labelText={`We have sent a 6 digit verification code to ${formik.values.phone}`}
                  containerStyle={{
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                  bottomText={
                    resendCode ? (
                      <p className="text-base text-gray-800 font-light pt-2 leading-5">
                        Please wait{' '}
                        <span
                          className={classNames(inputTheme.error, 'text-base')}
                        >
                          {counter} seconds
                        </span>{' '}
                        to resend.
                      </p>
                    ) : (
                      <p className="text-base text-gray-800 font-normal pt-2 leading-5">
                        Did not received the code?{' '}
                        <span
                          className="text-theme-primary cursor-pointer"
                          onClick={onResend}
                        >
                          Resend
                        </span>{' '}
                        or try a{' '}
                        <span
                          className="text-theme-primary cursor-pointer"
                          onClick={() => {
                            navigate(
                              new URL(routes.loginVerificationMethod)
                                .removeParams(['sessionId'])
                                .getUrl()
                            );
                          }}
                        >
                          different verification method
                        </span>
                      </p>
                    )
                  }
                />
              </div>
              <div className="pb-3 w-full ">
                <Button
                  type="submit"
                  label="Sign In"
                  primary={true}
                  theme={buttonTheme.primary}
                  isLoading={
                    loading ||
                    otpApi.loading ||
                    recaptchaLoading ||
                    success === true
                  }
                  loader={<Loader height={15} width={15} />}
                  disabled={
                    loading ||
                    otpApi.loading ||
                    recaptchaLoading ||
                    success === true
                  }
                  width="100%"
                  height="41px"
                />
              </div>
              <div className="pt-2 text-center font-normal text-theme-brown">
                <p>
                  By continuing above, you agree to our{' '}
                  <a
                    className="text-theme-primary cursor-pointer leading-6"
                    href="https://www.doctoroncall.com.my/termsOfService"
                  >
                    Terms & Conditions
                  </a>
                </p>
              </div>
              <br />
              {formik.errors?.generalError ? (
                <ErrorDisplayInline error={formik.errors.generalError} />
              ) : null}
            </form>
          </main>
        </div>
        <div className="flex-1"> </div>
        <div className="flex-1"> </div>
      </div>
    </Layout>
  );
}

export default LoginVerification;
