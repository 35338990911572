import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function GoBackAndInfo(props) {
  const navigate = useNavigate();
  const { showBack } = props;
  return (
    <div className="flex justify-between flex-1 w-full pt-6 px-6">
      <div className="">
        {showBack ? (
          <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => navigate(-1)}
            className="cursor-pointer"
          >
            <path
              d="M8.75 14.818L1.25 8.00001L8.75 1.18201"
              stroke="black"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : null}
      </div>
      <div>
        <a
          href="https://www.doctoroncall.com.my/help"
          rel="noreferrer"
          target="_blank"
        >
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.65 14.8281V14.4219C11.65 14.0052 11.6891 13.6484 11.7672 13.3516C11.8453 13.0495 11.9808 12.7682 12.1735 12.5078C12.3714 12.2474 12.6448 11.9714 12.9938 11.6797C13.4 11.3411 13.7203 11.0547 13.9547 10.8203C14.1943 10.5807 14.3662 10.3411 14.4703 10.1016C14.5745 9.86198 14.6266 9.57292 14.6266 9.23438C14.6266 8.69271 14.4521 8.27865 14.1031 7.99219C13.7542 7.70052 13.262 7.55469 12.6266 7.55469C12.111 7.55469 11.6526 7.61979 11.2516 7.75C10.8505 7.88021 10.4651 8.03646 10.0953 8.21875L9.64221 7.17188C10.0693 6.94792 10.5328 6.76562 11.0328 6.625C11.5328 6.48438 12.0901 6.41406 12.7047 6.41406C13.7099 6.41406 14.4886 6.66406 15.0406 7.16406C15.5927 7.65885 15.8688 8.34115 15.8688 9.21094C15.8688 9.69531 15.7906 10.1094 15.6344 10.4531C15.4781 10.7917 15.2568 11.1042 14.9703 11.3906C14.6839 11.6719 14.3453 11.9714 13.9547 12.2891C13.611 12.5807 13.3479 12.8385 13.1656 13.0625C12.9834 13.2812 12.8584 13.5078 12.7906 13.7422C12.7229 13.9714 12.6891 14.2474 12.6891 14.5703V14.8281H11.65ZM11.3063 17.1875C11.3063 16.8229 11.3922 16.5651 11.5641 16.4141C11.736 16.2578 11.9573 16.1797 12.2281 16.1797C12.4834 16.1797 12.6995 16.2578 12.8766 16.4141C13.0589 16.5651 13.15 16.8229 13.15 17.1875C13.15 17.5469 13.0589 17.8099 12.8766 17.9766C12.6995 18.138 12.4834 18.2188 12.2281 18.2188C11.9573 18.2188 11.736 18.138 11.5641 17.9766C11.3922 17.8099 11.3063 17.5469 11.3063 17.1875Z"
              fill="#3C3C3C"
            />
            {/* eslint-disable */}
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13 25C10.6266 25 8.30655 24.2962 6.33316 22.9776C4.35977 21.6591 2.8217 19.7849 1.91345 17.5922C1.0052 15.3995 0.767559 12.9867 1.23058 10.6589C1.6936 8.33115 2.83649 6.19295 4.51472 4.51472C6.19295 2.83649 8.33115 1.6936 10.6589 1.23058C12.9867 0.767559 15.3995 1.0052 17.5922 1.91345C19.7849 2.8217 21.6591 4.35977 22.9776 6.33316C24.2962 8.30655 25 10.6266 25 13C25 16.1826 23.7357 19.2348 21.4853 21.4853C19.2348 23.7357 16.1826 25 13 25V25Z"
              stroke="#3C3C3C"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}

export default GoBackAndInfo;

GoBackAndInfo.propTypes = {
  showBack: PropTypes.bool.isRequired,
};

GoBackAndInfo.defaultProps = {
  showBack: true,
};
